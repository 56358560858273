import React, { createContext } from 'react';

const UserStatusContext = createContext();

const UserStatusProvider = ({ children, value }) => (
  <UserStatusContext.Provider value={{ userStatus: value.status }}>
    {children}
  </UserStatusContext.Provider>
);

export { UserStatusContext, UserStatusProvider };
