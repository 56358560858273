import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { PrismicRichText, PrismicText } from '@prismicio/react';
import { useMediaQuery } from '../../../core/hooks/useMediaQuery';

const QuoutesCarousel = ({ slice }) => {
  const isMobileDevice = useMediaQuery('(max-width: 992px)');

  const directionButtons = (direction) => {
    return (
      <span
        aria-hidden="true"
        className={direction === 'Next' ? 'button-next' : 'button-prev'}
      ></span>
    );
  };

  return (
    <Row className="shadow-none">
      <Col>
        <Card className="mb-0 pe-0 bg-white shadow-none">
          <Card.Body>
            <h3 className="text-primary">
              <PrismicText field={slice.primary.title} />
            </h3>
            <Carousel
              indicators={false}
              controls={isMobileDevice ? false : true}
              touch={true}
              nextIcon={directionButtons('Next')}
              prevIcon={directionButtons('Previous')}
            >
              {slice.items.map((q, index) => (
                <Carousel.Item key={index} interval={1000 * q.interval}>
                  <figure>
                    <blockquote className="blockquote">
                      <PrismicRichText field={q.quote} />
                    </blockquote>
                    <figcaption>
                      <strong>
                        <PrismicText field={q.quoteteller} />
                      </strong>
                      <br />
                      <cite title="Source Title">
                        <PrismicText field={q.container} />
                      </cite>
                    </figcaption>
                  </figure>
                </Carousel.Item>
              ))}
            </Carousel>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default QuoutesCarousel;
