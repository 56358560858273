import { PrismicText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import MultiItemCarousel from '../../../components/MultiItemCarousel';
import { useMediaQuery } from '../../../core/hooks/useMediaQuery';

const LogoCarousel = ({ slice }) => {
  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 992px)');
  const isLaptop = useMediaQuery('(max-width: 1200px)');
  const isDesktop = useMediaQuery('(max-width: 1400px)');

  const CarouselImages = () => {
    if (isMobile) return 1;
    else if (isTablet) return 2;
    else if (isLaptop) return 3;
    else if (isDesktop) return 4;
    else return 5;
  };

  return (
    <Row className="shadow-none">
      <Col>
        <Card className="mb-0 bg-white shadow-none">
          <Card.Body>
            <h3 className="text-primary">
              <PrismicText field={slice.primary.small_picture_carousel_title} />
            </h3>
            <MultiItemCarousel show={CarouselImages()} infiniteLoop={true}>
              {slice.items.map((item) => {
                return (
                  <div key={item.company_logo.url}>
                    <div style={{ padding: 8 }}>
                      <img
                        className="d-block w-30 h-30"
                        src={prismicH.asImageSrc(item.company_logo)}
                        alt={item.company_logo.alt}
                        style={{ width: '200px', height: '200px' }}
                      />
                    </div>
                  </div>
                );
              })}
            </MultiItemCarousel>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LogoCarousel;
