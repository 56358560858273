// @flow
import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Logo from '../../assets/TURTLE_Logo_Claim.svg';

import './scss/AccountLayout.scss';

const AccountLayout = ({ bottomLinks, carousel, children, quotesCarousel }) => {
  useEffect(() => {
    if (document.body) document.body.classList.add('authentication-bg');

    return () => {
      if (document.body) document.body.classList.remove('authentication-bg');
    };
  }, []);

  return (
    <>
      <div
        className="bg-white shadow position-fixed w-100"
        style={{ zIndex: '100' }}
      >
        <Row>
          <Col>
            <Container className="pt-2 pb-2">
              <a href="https://go-turtle.com">
                <img src={Logo} alt="Logo Turtle" height="40" />
              </a>
            </Container>
          </Col>
        </Row>
      </div>

      <div className="account-pages pt-1 pt-sm-1 pb-4 pb-sm-5">
        <Container className="mt-4">
          {carousel && (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5} xxl={4}>
                {carousel}
              </Col>
            </Row>
          )}
          <Row className="justify-content-start">
            <Col md={8} lg={6} xl={5} xxl={5}>
              <Card className="shadow-none bg-transparent">
                <Card.Body className="bg-transparent">{children}</Card.Body>
              </Card>
              {bottomLinks}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white">
        <Container>{quotesCarousel}</Container>
        <div className="bg-white bottom-turtle-pattern"></div>
      </div>

      {/* <footer className="footer footer-alt">© 2022 Turtle GmbH</footer> */}
    </>
  );
};

export default AccountLayout;
