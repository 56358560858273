import { useState } from 'react';

export default () => {
  const [steps, setSteps] = useState([]);
  const [enabled, setEnabled] = useState(false);

  return {
    steps,
    enabled,
    setSteps,
    setEnabled
  };
};
