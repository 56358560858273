const Texts = {
  GENERAL: {
    COPYRIGHT: '© 2022 Turtle GmbH',
    UPLOAD_FILE: {
      ON_SUCESS: 'File was upload successfully.',
      ON_ERROR: 'File could not be saved, please try again later.'
    }
  },
  PROFILE: {
    NOTIFICATIONS: {
      SAVING: 'Saving your changes...',
      CHANGES_SAVED: 'The changes were saved successfully.',
      ERROR_SAVING: 'Changes could not be saved, please try again later.'
    },
    STRENGTH_LEVELS: {
      GLOW_TEXT:
        'You’re doing great! But looks like your seagoing experience and availability date was not updated in a while. Let’s go and update them now!',

      SMALL_FLAME_TEXT:
        'You’re heating up! But looks like your seagoing experience and availability date was not updated in a while. Let’s go and update them now!',

      ON_FIRE_TEXT:
        'Good job, you’re on fire! To make a blaze, you need to add your latest CoC to your profile, it has expired',

      BLAZE_TEXT:
        'Amazing, your engagement is blazing! It can’t get any better. You have an up-to-date profile and are attractive to the shipping companies. Be ready to respond quickly to job opportunities, good luck!'
    }
  },
  COMPANIES: {
    LIKE_ALL_COMPANIES: {
      TEXT: 'All companies you have liked will be able to find your anonymous profile in their searches. Do you want to like all companies shown in the page?',
      ON_SUCESS: 'Your request was sent successfully.',
      ON_ERROR: 'Your request could not be sent, please try again later.'
    },
    UNLIKE_ALL_COMPANIES: {
      TEXT: 'All companies you have disliked will be unable to find your anonymous profile in their searches. Do you want to dislike all companies shown in the page?',
      ON_SUCESS: 'Your request was sent successfully.',
      ON_ERROR: 'Your request could not be sent, please try again later.'
    }
  },
  DASHBOARD: {
    INVITE_FRIEND: {
      TEXT: (
        <p>
          For every seafarer, you invite with your link, that signs up and confirm
          their registration before 31st of May 2024, you automatically take part in
          a lucky draw where you can win premium Bose Headphones. The more invites,
          the better chances of winning! Full{' '}
          <a href="https://www.go-turtle.com/terms-and-conditions" target="_blank">
            Terms and Conditions
          </a>{' '}
          here.
        </p>
      ), //'For every seafarer, you invite with your link, that signs up and confirm their registration before 31st of May 2024, you automatically take part in a lucky draw where you can win premium Bose Headphones. The more invites, the better chances of winning! Full Terms and Conditions here.',
      ON_SUCESS: 'Your invitation was sent successfully.',
      ON_ERROR: 'Your invitation could not be sent, please try again later.'
    },
    SEND_TO_PHONE: {
      TEXT: 'Never miss a job opportunity! Install the TURTLE app on your phone and get notified about job opportunities and news. Send the installation link to your email address to easily open and install the app on your phone.',
      ON_SUCESS: 'Your invitation was sent successfully.',
      ON_ERROR: 'Your invitation could not be sent, please try again later.'
    },
    AVAILABILITY: {
      ON_SUCESS: 'Your availability was saved successfully.',
      ON_ERROR: 'Your availability could not be updated, please try again later.',
      ON_CONFIRM_SUCCESS: 'Your availability was confirmed successfully.',
      ON_CONFIRM_ERROR:
        'Your availability could not be confirmed, please try again later.'
    },
    SEA_SERVICE: {
      ON_SUCESS: 'Your last sea service date was saved successfully.',
      ON_ERROR:
        'Your last sea service date could not be updated, please try again later.'
    },
    INSTALL_APP_MODAL_TEXT:
      'Never miss a job opportunity! Get the TURTLE app for easy access from your phone.',
    LIKES: {
      TITLE: 'Company Likes',
      MESSAGE: 'Only companies who you liked can find you in their search.',
      TITLE_NEW_LOGIC: 'Company Preferences',
      MESSAGE_NEW_LOGIC:
        'You can decide which companies can find you in their searches.'
    },
    ACHIEVEMENTS: {
      TITLE: 'YOUR ACHIEVEMENTS',
      MESSAGE:
        'We celebrate your milestones with achievement badges. With each badge added, you make yourself more attractive to companies.'
    }
  },
  OPPORTUNITIES: {
    TOOLTIP:
      'Below are all job opportunities where you meet the basic requirements for hiring. The jobs marked “Confirmation Needed” requires you to confirm your interest and availability to be considered for the position.',
    REACT_TO_OPPORTUNITY: {
      ON_SUCESS: 'Changes were saved successfully.',
      ON_ERROR: 'Changes could not be saved, please try again later.'
    }
  },
  JOBS: {
    REACT_TO_JOB: {
      ON_SUCESS: 'Changes were saved successfully.',
      ON_ERROR: 'Changes could not be saved, please try again later.'
    }
  },
  INTERVIEWS: {
    REACT_TO_INTERVIEW: {
      ON_SUCESS: 'Changes were saved successfully.',
      ON_ERROR: 'Changes could not be saved, please try again later.'
    }
  },
  LOGIN: {
    WAITING:
      'We are preparing your upgraded TURTLE experience. This can take a few minutes, please wait...',
    WRONG_CREDENTIALS:
      'Your email or password is incorrect, please try again or reset your password through Forgot your password.',
    GENERAL_LOGIN_ERROR: 'We could not log you in, please try again later.',
    ACCOUNT_NOT_CONFIRMED:
      'Your email must be confirmed before you can login. Please check your email and click “Confirm Registration” in the last message you received. Also check your spam folder.'
  },
  FORGOT_PASSWORD: {
    USER_NOT_FOUND: 'Users does not exist, please check the email address. ',
    CODE_MISMATCH: 'Invalid verification code provided, please try again.'
  },
  SETTINGS: {
    SAVING: 'Saving your changes...',
    ERROR_SAVING: 'Changes could not be saved, please try again later.',
    CHANGES_SAVED: 'The changes were saved successfully.'
  },
  GENERAL_ERRORS: {
    ERROR: 'An error occurred, please try again later.',
    ERROR_CURRENT_SESSION: 'Your session is no longer valid, please login again.'
  },
  SALARY_BENCHMARK: {
    GETTING_DATA: 'There was an error getting the data, please try again later.',
    UPDATE_SALARY: {
      ON_SUCESS: 'Your salary expectation was updated successfully.',
      ON_ERROR: 'Changes could not be saved, please try again later.'
    }
  },
  APP: {
    REQUEST_INSTALLATION_INSTRUCTIONS: {
      ON_SUCESS: 'Instructions were sent to your registered email address.',
      ON_ERROR: 'Instructions could not been sent, please try again later.'
    }
  },
  VACANCIES: {
    VACANCY_INTEREST_MODAL_CONFIRMED: {
      HEADER: 'You must complete your profile',
      TEXT: 'To be considered for this job, you need to complete your TURTLE profile. Do it now to access all opportunities on TURTLE!',
      BUTTON: 'COMPLETE PROFILE'
    },
    VACANCY_INTEREST_MODAL: {
      HEADER: 'Thank you for your interest!',
      TEXT: 'Great that you found an interesting vacancy! The company will review your profile and reach out to you if you fit the requirements. Make sure your profile is complete and updated to have the best chance to get an offer.',
      BUTTON: 'CLOSE'
    }
  }
};

export default Texts;
