import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';

const GenericModal = ({
  onCancel = () => {},
  onConfirm = () => {},
  onHide,
  className = 'info',
  confirmButtonVariant = 'light',
  title = '',
  confirmButtonText = 'Save changes',
  cancelButtonText = 'Close',
  children,
  loading = false,
  disabledConfirm = false,
  isBlankModel = false,
  hideConfirmButton = false,
  hideCloseButton = false,
  closeButton = true,
  skipCloseOnConfirm = false
}) => {
  const confirm = () => {
    onConfirm();
    if (!skipCloseOnConfirm) {
      onCancel();
    }
  };

  return (
    <>
      <Modal.Header
        onHide={() => {
          onHide ? onHide() : onCancel();
        }}
        closeButton={closeButton}
        className={classNames('modal-colored-header', `bg-${className}`)}
      >
        <h4 className="modal-title">{title}</h4>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>

      {isBlankModel ? null : (
        <Modal.Footer className="justify-content-center  justify-content-sm-end">
          {!hideCloseButton && (
            <Button
              variant={confirmButtonVariant}
              onClick={onCancel}
              disabled={loading}
            >
              {cancelButtonText}
            </Button>
          )}{' '}
          {!hideConfirmButton && (
            <Button
              variant={className}
              onClick={confirm}
              disabled={loading || disabledConfirm}
            >
              {confirmButtonText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </>
  );
};
export default GenericModal;
