import api from './api';

const featureFlagsApi = (api) => {
  const endpoint = '/flags';

  const fetchFeaturesFlags = ({ headers }) => {
    const url = `${endpoint}`;
    const config = { headers };
    return api.get(url, config);
  };
  return { fetchFeaturesFlags };
};

export default featureFlagsApi(api);
