import { DashboardActionTypes } from './constants';

export const updateDashboardBasicData = (basicData) => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA,
  payload: basicData
});

export const updateDashboardBasicDataSucceeded = (basicData) => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA_SUCCEEDED,
  payload: basicData
});

export const updateDashboardBasicDataFailed = () => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA_FAILED,
  payload: null
});
