import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalContext } from '../contexts/Modals';

const ModalContainer = () => {
  const { modalContent, handleModal, modal } = useContext(ModalContext);

  return (
    <Modal
      show={modal}
      onHide={() => handleModal(false)}
      backdrop={modalContent?.props?.backdrop || true}
      keyboard={modalContent?.props?.keyboard || true}
    >
      {modalContent}
    </Modal>
  );
};

export default ModalContainer;
