const OpportunityReaction = {
  UNDEFINED: 0,
  NOT_AVAILABLE: 1,
  INTERESTED: 2,
  NOT_INTERESTED: 3
};

export const CognitoErrors = {
  USER_NOT_FOUND: 'UserNotFoundException',
  USER_NOT_AUTHORIZED: 'NotAuthorizedException',
  INVALID_PASSWORD: 'InvalidPasswordException',
  EXPIRED_PASSWORD: 'ExpiredPasswordException',
  USER_DISABLED: 'UserDisabledException',
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  INVALID_PARAMETERS: 'InvalidParameterException',
  INVALID_USER_PASSWORD_TOO_MANY_FAILURES:
    'InvalidUserPasswordTooManyAttemptsException',
  INVALID_USER_PASSWORD_TOO_MANY_REQUESTS: 'InvalidUserPasswordTokenException',
  INVALID_USER_PASSWORD_REQUIRED_CHANGE: 'PasswordResetRequiredException',
  CODE_MISMATCH: 'CodeMismatchException'
};

export default OpportunityReaction;
