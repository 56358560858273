import React, { useState, useContext } from 'react';
import GenericModal from '../../../components/GenericModal';
import { AccountContext } from '../../../contexts/Accounts';
import toast from 'react-hot-toast';
import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import turtleSearching from '../../../assets/images/TURTLE_Searching.png';
import Image from 'react-bootstrap/Image';
import Texts from '../../../data/texts';
import cvApi from '../../../core/services/cvDataExtractorApi';

const WelcomeModal = ({ onCancel, welcomeCVScanVersion }) => {
  const { user } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [modelTitle, setModelTitle] = useState('Welcome to TURTLE!');
  const [steps, setSteps] = useState(1);

  const [fileCV, setFileCV] = useState();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const history = useHistory();

  const onManualProfile = () => {
    onCancel();
    history.push('/profile');
  };

  const handleSteps = () => {
    let nextStep = steps + 1;
    if (nextStep === 2 || nextStep === 3) {
      setModelTitle('We want to get to know you!');
    } else if (nextStep === 4) {
      setModelTitle('Thank you for uploading your CV!');
    } else {
      setModelTitle('Welcome to TURTLE!');
    }
    setSteps(nextStep);
  };

  const uploadHandler = (e) => {
    const fileInput = e.target;

    if (
      fileInput.files &&
      fileInput.files[0] &&
      fileInput.files[0].type === 'application/pdf'
    ) {
      const uploadedFile = fileInput.files[0];
      setUploadError(null);
      setFileCV(uploadedFile);
      setIsUploaded(true);
      handleSteps();
    } else {
      setUploadError('Upload PDF file only.');
    }
  };

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    cvApi
      .scanUploadFile({
        formData,
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        },
        scan: true
      })
      .then((res) => {
        toast.success(Texts.GENERAL.UPLOAD_FILE.ON_SUCESS);
        handleSteps();
      })
      .catch((err) => {
        setUploadError(Texts.GENERAL.UPLOAD_FILE.ON_ERROR);
        toast.error(Texts.GENERAL.UPLOAD_FILE.ON_ERROR);
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    if (e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      setFileCV(droppedFile);
      setIsUploaded(true);
      handleSteps();
    }
  };

  const WelcomeContentV1 = () => (
    <>
      <Row>
        <h4 className="text-center pb-2">
          Connecting seafarers and shipping companies <br className="break-line" />{' '}
          directly and fair.
        </h4>
      </Row>
      <Row className="justify-content-center pb-2">
        <img
          src="https://media.giphy.com/media/ggtpYV17RP9lTbc542/giphy.gif"
          alt="GIF"
          className="welcome-gif"
        />
      </Row>
      <Row>
        <h4 className="text-center pb-2">
          To get started - complete your TURTLE profile now!
        </h4>
      </Row>
      <Row className="justify-content-center ">
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            onManualProfile();
          }}
          className="welcome-popup-button"
        >
          Full Steam Ahead!
        </Button>
      </Row>
    </>
  );

  const welcomeV2Step1 = () => (
    <>
      <Row>
        <h4 className="text-start pb-2">
          Connecting seafarers and shipping companies <br className="break-line" />
          directly and fair.
        </h4>
      </Row>
      <Row className="justify-content-center pb-2">
        <img
          src="https://media.giphy.com/media/ggtpYV17RP9lTbc542/giphy.gif"
          alt="GIF"
          className="welcome-gif"
        />
      </Row>
      <Row className="justify-content-center mt-2">
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            handleSteps();
          }}
          className="welcome-popup-button"
        >
          Full Steam Ahead!
        </Button>
      </Row>
    </>
  );

  const welcomeV2Step2 = () => (
    <>
      <Row>
        <h4 className="text-center pb-2">
          <i className="h3 uil-bolt-alt text-dark" /> Auto-fill TURTLE profile
        </h4>
      </Row>
      <Row
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`upload-drop-area ${isDragOver ? 'drag-over' : ''}`}
      >
        <div className="UploadFileDocument ">
          <div className={`upload-section ${uploadError && 'critical'}`}>
            <div className="file-upload-input">
              <input
                type="file"
                onChange={(e) => {
                  uploadHandler(e);
                }}
              />
              <div className="upload-details">
                <i className="h2 uil-cloud-upload mt-0" />
                <span>Upload your CV in PDF-format</span>
                <span>Drag and drop or click here</span>
                Choose a file...
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        {' '}
        {uploadError && (
          <div className="text-center pt-2 text-danger">{uploadError}</div>
        )}{' '}
      </Row>
      <Row className="justify-content-center py-2">OR</Row>
      <Row className="justify-content-around ">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            onManualProfile();
          }}
          className="welcome-popup-button"
        >
          Fill-in manually
        </Button>
      </Row>
    </>
  );

  const welcomeV2Step3 = () => (
    <>
      <Row>
        <h4 className="text-center pb-2">
          <i className="h3 uil-bolt-alt text-dark" /> Auto-fill TURTLE profile
        </h4>
      </Row>
      <Row xs={4} className="justify-content-center ">
        <div className="UploadFileDocument" style={{ width: '70%' }}>
          <div className={`upload-section ${uploadError && 'critical'}`}>
            <i
              className="h3 uil-times text-danger cross-button"
              onClick={() => {
                setFileCV(null);
                setIsUploaded(false);
                setSteps((prevSteps) => prevSteps - 1);
              }}
            />
            <div className="file-upload-input">
              <div className="upload-details">
                <h4 className="uploaded-file-text">
                  <i
                    className="h3 uil-file-alt text-primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      setFileCV(null);
                      setIsUploaded(false);
                      setSteps((prevSteps) => prevSteps - 1);
                    }}
                  />
                  {fileCV && !uploadError
                    ? fileCV?.name
                    : 'Unable to load file , Please try again '}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row className="justify-content-around py-2 mt-2">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            if (!isUploaded && !fileCV)
              document.getElementById('turtleProfileInput')?.click();
            else {
              handleUpload(fileCV);
            }
          }}
          className="welcome-popup-button"
        >
          Scan My CV
        </Button>
      </Row>
    </>
  );

  const welcomeV2Step4 = () => (
    <>
      <Row>
        <h4 className="text-center pb-2">
          It looks great! TURTLE is processing the data, it will take a few minutes.
        </h4>
      </Row>
      <Row>
        <h4 className="text-center pb-2">
          We will notify you once the information is added to your TURTLE profile!
        </h4>
      </Row>
      <Row className="justify-content-center pb-2">
        <Image
          src={turtleSearching}
          alt="Turtle Searching"
          roundedCircle
          style={{ height: '150px', width: '150px' }}
          className="img-thumbnail"
        />
      </Row>
      <Row className="justify-content-center ">
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            onCancel();
          }}
          className="w-25 mt-2"
        >
          OK
        </Button>
      </Row>
    </>
  );

  const WelcomeContentV2 = () => {
    switch (steps) {
      case 1:
        return welcomeV2Step1();
      case 2:
        return welcomeV2Step2();
      case 3:
        return welcomeV2Step3();
      case 4:
        return welcomeV2Step4();
      default:
        return WelcomeContentV1();
    }
  };

  return (
    <GenericModal
      className="primary"
      title={modelTitle}
      isBlankModel={true}
      loading={loading}
      onCancel={onCancel}
    >
      {welcomeCVScanVersion ? WelcomeContentV2() : WelcomeContentV1()}
    </GenericModal>
  );
};

export default WelcomeModal;
