export const hsIdentifyUser = (userEmail) => {
  try {
    if (userEmail) {
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email: userEmail
        }
      ]);
    }
  } catch (error) {
    console.log(error);
  }
};
export const hsIdentifyUserNewSignUp = (
  registrationFormValues,
  signUpVersion = 1,
  referral = ''
) => {
  const formVersion = signUpVersion === 1 ? 'three_steps_form' : 'two_steps_form';

  try {
    if (registrationFormValues.email) {
      const payload = {
        email: registrationFormValues.email,
        current_rank: hubspotRankValuesMap.get(registrationFormValues.currentRank),
        preferred_vessel_type_: hubspotVesselTypeValuesMap.get(
          registrationFormValues.preferredVessel
        ),
        salary__number_: registrationFormValues.salary,
        firstname: registrationFormValues.firstName,
        currency: registrationFormValues.currency,
        country_code: registrationFormValues.countryCode,
        mobilephone: registrationFormValues.phoneNumber,
        new_registration_used: 'true',
        lifecyclestage: 'subscriber',
        registration_form_version: formVersion,
        referral_id__t2_: referral
      };
      for (const key in payload) {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      }
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', payload]);
    }
  } catch (error) {
    console.log(error);
  }
};

export const hsTrackPageView = (pathURL) => {
  try {
    if (pathURL) {
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', pathURL]);
      _hsq.push(['trackPageView']);
    }
  } catch (error) {
    console.log(error);
  }
};

const hubspotRankValuesMap = new Map([
  ['MASTER', '1'],
  ['CHIEF_OFFICER', '2'],
  ['2ND_OFFICER', '3'],
  ['3RD_OFFICER', '4'],
  ['4TH_OFFICER', '5'],
  ['CHIEF_ENGINEER', '6'],
  ['2ND_ENGINEER', '7'],
  ['3RD_ENGINEER', '8'],
  ['4TH_ENGINEER', '9'],
  ['ELECTRO-TECHNICAL_OFFICER_(ETO)', '27'],
  ['ELECTRICIAN', '11'],
  ['JUNIOR_ELECTRICIAN', '12'],
  ['MOTORMAN', '13'],
  ['FITTER', '14'],
  ['OILER', '15'],
  ['WIPER', '16'],
  ['BOSUN', '17'],
  ['AB', '18'],
  ['OS', '19'],
  ['CHIEF_COOK', '20'],
  ['COOK', '21'],
  ['STEWARD', '22'],
  ['MESSMAN', '23'],
  ['DECK_CADET', '24'],
  ['ENGINE_CADET', '25'],
  ['ELECTRICIAN_CADET', '26'],
  ['GAS_ENGINEER', '28'],
  ['PUMP_MAN', 'Pump Man'],
  ['JUNIOR_ETO', 'Junior ETO']
]);

const hubspotVesselTypeValuesMap = new Map([
  ['CONTAINER', 'Container'],
  ['BULK_CARRIER', 'Bulk Carrier'],
  ['CHEMICAL_TANKER', 'Chemical Tanker'],
  ['PRODUCT_TANKER', 'Product Tanker'],
  ['CRUDE_TANKER', 'Crude Tanker'],
  ['LNG_CARRIER', 'LNG Carrier'],
  ['LPG_CARRIER', 'LPG Carrier'],
  ['GENERAL_CARGO_MPP', 'General Cargo / MPP'],
  ['CAR_CARRIER', 'Car Carrier'],
  ['CRUISE', 'Cruise'],
  ['YACHT', 'Yacht'],
  ['OFFSHORE', 'Offshore'],
  ['OTHER', 'Other'],
  ['CABLE_LAYER', 'Cable Layer'],
  ['DREDGER', 'Dredger'],
  ['DRILLING_SHIP', 'Drilling Ship'],
  ['FPSO', 'Floating Production Storage and Offloading Unit (FPSO)'],
  ['FSO', 'FSO'],
  ['OFFSHORE_SUPPORT_VESSEL', 'Offshore Support Vessel (OSV)'],
  ['OFFSHORE_TAG_SUPPLY_SHIP', 'Offshore Tug/Supply Ship'],
  ['PIPE_LAYER', 'Pipe Layer'],
  ['PLATFORM_SUPPLY_SHIP', 'Platform Supply Vessel (PSV)'],
  ['AHTS', 'Anchor Handling Tug Supply Vessel (AHTS)'],
  ['NUCLEAR_FUEL_CARRIER', 'Nuclear Fuel Carrier'],
  ['RO_RO', 'roro'],
  ['RO_PAX', 'ropax'],
  ['PASSENGER', 'Passenger'],
  ['SHORE_JOBS', 'Shore Jobs']
]);
