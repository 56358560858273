import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SliceZone, useSinglePrismicDocument } from '@prismicio/react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import WizardRegistration from './components/WizardRegistration';
import QuoutesCarousel from './components/QuoutesCarousel';
import LogoCarousel from './components/LogoCarousel';
import publicApi from '../../core/services/publicApi';
import { REGISTRATION_FORM_BKP } from '../../constants';

const SignUp = () => {
  const [submitError, setSubmitError] = useState();
  const [formValues, setFormValues] = useState({});
  const [firstStepDone, setFirstStepDone] = useState(false);
  const location = useLocation();
  const [ranks, setRanks] = useState([]);
  const [shipTypes, setShipTypes] = useState([]);
  const [document] = useSinglePrismicDocument('registration');
  const [referral, setReferral] = useState('');

  const reOrderElements = (shipSegments) => {
    const offshoreIndex = shipSegments.findIndex(
      (shipSegment) => shipSegment.type === 'OFFSHORE'
    );
    const offshoreSegment = shipSegments[offshoreIndex];
    shipSegments.splice(offshoreIndex, 1);
    shipSegments.push(offshoreSegment);
    return shipSegments;
  };

  useEffect(() => {
    publicApi
      .fetchRanks()
      .then((res) => {
        const ranksArray = res.data.result.filter((rank) => rank.type !== 'OTHER');
        setRanks(ranksArray);
      })
      .catch(() => setRanks([]));
  }, []);

  useEffect(() => {
    publicApi
      .fetchShipSegments()
      .then((res) => {
        if (res?.data?.result) {
          setShipTypes(reOrderElements(res.data.result));
        }
      })
      .catch(() => setShipTypes([]));
  }, []);

  // It recovers information from query params and also
  // from local storage.
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.replace('?', ''));

    let email = (urlParams.get('email') || '').trim();
    email = decodeURIComponent(email).toLowerCase();
    const currentRank = (urlParams.get('currentRank') || '').trim();
    const tandc = (urlParams.get('tandc') || '').trim().toLowerCase();
    const tandcBoolean = tandc === 'true';
    let ref = (urlParams.get('ref') || '').trim();

    setReferral(ref);

    let recoveredFormValues = {
      email,
      currentRank,
      tandc: tandcBoolean
    };

    try {
      let regFormBkp = localStorage.getItem(REGISTRATION_FORM_BKP);

      if (regFormBkp) {
        recoveredFormValues = JSON.parse(regFormBkp);
      }
    } catch (error) {}

    setFormValues(recoveredFormValues);

    if (
      recoveredFormValues.email !== '' &&
      recoveredFormValues.currentRank !== '' &&
      recoveredFormValues.tandc
    ) {
      setFirstStepDone(true);
    }
  }, []);

  const BottomLink = () => {
    return (
      <Row className="mt-3">
        <Col className="text-center">
          <p className="text-muted">
            Already have account?{' '}
            <Link to={{ pathname: '/login' }} className="ms-1 text-info">
              <b>Log In</b>
            </Link>
          </p>
        </Col>

        <Col>
          <div className="circle-badge mt-5 d-none d-md-block">
            <div className="center-text">
              <h4>
                {' '}
                Supersafe &amp; <br /> Free of Charge
              </h4>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  const QuotesSlider = () => {
    return (
      <>
        {document && (
          <SliceZone
            slices={document.data.body}
            components={{
              cards_carousel: QuoutesCarousel,
              smallpicturecarousel: LogoCarousel
            }}
          />
        )}
      </>
    );
  };
  return (
    <AccountLayout bottomLinks={<BottomLink />} quotesCarousel={<QuotesSlider />}>
      {submitError && (
        <Alert variant="danger" className="my-2">
          {submitError}
        </Alert>
      )}
      {firstStepDone && (
        <WizardRegistration
          ranks={ranks}
          shipTypes={shipTypes}
          initialValues={formValues}
          firstStepDone={firstStepDone}
          onError={setSubmitError}
          referral={referral}
        />
      )}
      {!firstStepDone && (
        <WizardRegistration
          ranks={ranks}
          shipTypes={shipTypes}
          initialValues={formValues}
          firstStepDone={firstStepDone}
          onError={setSubmitError}
          referral={referral}
        />
      )}
    </AccountLayout>
  );
};

export default SignUp;
