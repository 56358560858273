import { UserActionTypes } from './constants';

const INIT_STATE = {
  userBasicInfo: {
    profileId: 0,
    firstName: 'Seafarer',
    lastName: '',
    seafarerPhoto: '',
    status: '',
    currentRank: {
      id: 0,
      value: '',
      label: ''
    },
    currentPreferredVessel: {
      id: 0,
      value: '',
      label: ''
    },
    lastDisembarkationDate: ''
  }
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER_BASIC_INFO: {
      return {
        ...state,
        userBasicInfo: action.payload
      };
    }
    default:
      return state;
  }
};

export default User;
