import React, { useEffect, useState } from 'react';
import AccountLayout from '../../../layouts/Authentication/AccountLayout';
import publicApi from '../../../core/services/publicApi';
import CRUnauthorized from '../Components/CRUnauthorized';
import CRAlreadyReacted from '../Components/CRAlreadyReacted';
import CRGeneralError from '../Components/CRGeneralError';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { GetStatusFromErrorCode, StatusCodes } from '../Utils/utils';
import FormInput from '../../../components/FormInput';
import VerticalForm from '../../../components/VerticalForm';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const SuccessfullyRejected = () => {
  const history = useHistory();
  return (
    <div className="text-start w-100 m-auto">
      <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
        Thank you!,
      </h1>
      <div className="text-muted mb-4">
        <br />
        <strong>
          We will let you know as soon as there is a new opportunity for you!
        </strong>
        <br />
        <br />
        <div className="text-start">
          Make sure to always keep your availability and profile date up to date.
        </div>
        <br />
        <div className="mb-3 mb-0 text-center">
          <Button variant="danger" type="button" onClick={() => history.push('/')}>
            TO YOUR TURTLE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

const DefaultView = ({ onReject = (reason) => {} }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const history = useHistory();

  const defaultValues = {
    availability: false,
    salary: false,
    vessel: false,
    embarkation_date: false,
    company: false,
    other: false
  };

  const checkboxes = [
    {
      name: 'availability',
      label: 'I am not available (please specify your availability date below)',
      reason: 'I am not available'
    },
    {
      name: 'salary',
      label: 'Offered salary is too low',
      reason: 'Offered salary is too low'
    },
    {
      name: 'vessel',
      label: 'Vessel is not interesting/suitable',
      reason: 'Vessel is not interesting/suitable'
    },
    {
      name: 'embarkation_date',
      label: 'I need an earlier embarkation date',
      reason: 'I need an earlier embarkation date'
    },
    {
      name: 'company',
      label: 'Not interested in this company',
      reason: 'Not interested in this company'
    },
    {
      name: 'other',
      label: 'Other (please specify reason below)',
      reason: 'Other'
    }
  ];

  const handleReject = (reasons) => {
    setLoading(true);

    const reasonsStr = checkboxes
      .filter(({ name }) => reasons[name])
      .map(({ reason }) => reason)
      .join(' | ');

    const finalReasonsStr =
      reasonsStr.length > 0
        ? reasonsStr + (comment ? ' | ' + comment : '')
        : comment;

    onReject(finalReasonsStr);
  };

  return (
    <div className="text-start w-100 m-auto">
      <div className="text-muted mb-4">
        <br />
        <div className="text-primary mt-2 pt-2 mb-2">
          <h4 className="text-dark-50 fw-bold">
            Why are you not interested in this opportunity?
          </h4>
        </div>
        <br />
        <VerticalForm
          onSubmit={({ ...values }) => handleReject(values)}
          defaultValues={defaultValues}
        >
          <FormInput
            key={checkboxes[0].name}
            label={checkboxes[0].label}
            type="checkbox"
            name={checkboxes[0].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <FormInput
            key={checkboxes[1].name}
            label={checkboxes[1].label}
            type="checkbox"
            name={checkboxes[1].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <FormInput
            key={checkboxes[2].name}
            label={checkboxes[2].label}
            type="checkbox"
            name={checkboxes[2].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <FormInput
            key={checkboxes[3].name}
            label={checkboxes[3].label}
            type="checkbox"
            name={checkboxes[3].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <FormInput
            key={checkboxes[4].name}
            label={checkboxes[4].label}
            type="checkbox"
            name={checkboxes[4].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <FormInput
            key={checkboxes[5].name}
            label={checkboxes[5].label}
            type="checkbox"
            name={checkboxes[5].name}
            containerClass="mb-2 text-muted"
            disabled={loading}
          />
          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>
                  Please add any other information we should know:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  disabled={loading}
                  onChange={(e) =>
                    setComment((prev) => {
                      if (e?.target?.value?.length <= 200) {
                        return e.target.value;
                      } else {
                        return prev;
                      }
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <div className="mb-3 mb-0 text-center">
            <Button variant="danger" type="submit" disabled={loading}>
              REJECT OPPORTUNITY
            </Button>
          </div>
        </VerticalForm>
      </div>
    </div>
  );
};

const RejectCRPublicPage = ({}) => {
  const [statusCode, setStatusCode] = useState(StatusCodes.DEFAULT);
  const [tokens, setTokens] = useState({
    cr: '',
    sf: '',
    co: ''
  });
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.replace('?', ''));

    let token = (urlParams.get('token') || '').trim();
    token = decodeURIComponent(token).toLowerCase() || '';
    const tokenSegments = token.split('--') || [];

    if (tokenSegments.length === 3) {
      const cr = tokenSegments[0];
      const sf = tokenSegments[1];
      const co = tokenSegments[2];

      setTokens({
        cr: cr,
        sf: sf,
        co: co
      });
    } else {
      setStatusCode(StatusCodes.GENERAL_ERROR);
    }
  }, []);

  const handleReject = (reason) => {
    publicApi
      .rejectCR({
        data: {
          cr: tokens.cr,
          sf: tokens.sf,
          co: tokens.co,
          reject_reason: reason
        }
      })
      .then((res) => {
        setStatusCode(StatusCodes.OK);
      })
      .catch((err) => {
        const errorCode =
          err?.response?.data?.errorCode || StatusCodes.GENERAL_ERROR;

        setStatusCode(GetStatusFromErrorCode(errorCode));
      });
  };

  return (
    <AccountLayout bottomLinks={<></>}>
      {statusCode === StatusCodes.DEFAULT && <DefaultView onReject={handleReject} />}
      {statusCode === StatusCodes.OK && <SuccessfullyRejected />}
      {statusCode === StatusCodes.GENERAL_ERROR && <CRGeneralError />}
      {statusCode === StatusCodes.UNAUTHORIZED && <CRUnauthorized />}
      {statusCode === StatusCodes.ALREADY_REACTED && <CRAlreadyReacted />}
    </AccountLayout>
  );
};

export default RejectCRPublicPage;
