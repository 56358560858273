import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import GenericModal from '../../../components/GenericModal';
import { getTime } from 'date-fns';
import Texts from '../../../data/texts';
import DateTimeInput from '../../../components/Inputs/DateTimeInput';
import availabilityApi from '../../../core/services/availabilityApi';
import { AccountContext } from '../../../contexts/Accounts';
import handleDate from '../../../core/utils/get-ddMMyyy-date';
import { Button } from 'react-bootstrap';
import Loader from '../../../components/Loader';

const CheckAvailabilityModel = ({ onCancel, data, firstName, reduced = false }) => {
  const { user } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [notAvailableValue, setNotAvailableValue] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);

  useEffect(() => {
    if (data) {
      const { availableFrom, notAvailable } = data;
      setNotAvailableValue(notAvailable);
      if (!notAvailable) {
        setDateValue(Number(availableFrom) || null);
      }
    }
  }, [data]);

  const handleAvailabilityChange = (date) => {
    const availableFrom = getTime(new Date(date) / 1000);
    setDateValue(availableFrom);
    setNotAvailableValue(false);
    setLoading(true);
    toast.promise(
      availabilityApi
        .updateSeafarerAvailability({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          },
          body: {
            notAvailable: false,
            availableFrom
          }
        })
        .finally(() => {
          setLoading(false);
          if (onCancel) onCancel();
        }),
      {
        loading: 'Updating your availability date...',
        success: Texts.DASHBOARD.AVAILABILITY.ON_SUCESS,
        error: Texts.DASHBOARD.AVAILABILITY.ON_ERROR
      }
    );
  };

  const handleCheckboxToggle = (isChecked) => {
    setNotAvailableValue(isChecked);
    if (isChecked) {
      setLoading(true);
      toast.promise(
        availabilityApi
          .updateSeafarerAvailabilityToNotAvailable({
            headers: {
              Authorization: user.session.getIdToken().getJwtToken()
            }
          })
          .finally(() => {
            setLoading(false);
            if (onCancel) onCancel();
          }),
        {
          loading: 'Updating your availability...',
          success: Texts.DASHBOARD.AVAILABILITY.ON_SUCESS,
          error: Texts.DASHBOARD.AVAILABILITY.ON_ERROR
        }
      );
    }
  };

  const handleForcedUpdate = () => {
    if (data) {
      const { availableFrom, notAvailable } = data;
      notAvailable
        ? handleCheckboxToggle(notAvailable)
        : handleAvailabilityChange(availableFrom * 1000);
    }
  };

  useEffect(() => {
    if (isForceUpdate) {
      handleForcedUpdate();
    }
  }, [isForceUpdate]);

  const handleConfirm = () => {
    let confirmApiCall = availabilityApi.confirmSeafarerAvailability;

    if (notAvailableValue) {
      confirmApiCall = availabilityApi.confirmSeafarerNotAvailability;
    }

    toast.promise(
      confirmApiCall({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      }).finally(() => {
        setLoading(false);
        if (onCancel) onCancel();
      }),
      {
        loading: 'Confirming your availability...',
        success: Texts.DASHBOARD.AVAILABILITY.ON_CONFIRM_SUCCESS,
        error: Texts.DASHBOARD.AVAILABILITY.ON_CONFIRM_ERROR
      }
    );
  };

  return (
    <GenericModal
      onCancel={() => {
        onCancel();
        setIsForceUpdate(true);
      }}
      onConfirm={handleConfirm}
      className="primary"
      title={`${firstName}! Is your availability up-to-date?`}
      confirmButtonText="Confirm"
      cancelButtonText="Close"
      closeButton={false}
      loading={loading}
      isBlankModel={true}
    >
      {!reduced && (
        <Row className="mt-2 ms-2">
          <Col>
            <h4>Hi {firstName}!</h4>
            <p>Is your availability up-to-date?</p>
          </Col>
        </Row>
      )}
      <Row className="mt-2 ms-2">
        <Col>
          <label>Your Availability</label>
        </Col>
      </Row>
      <Row className="ms-2">
        <Col>
          <h5
            className="heading-box-height head-lg text-primary"
            style={{ width: '100%' }}
          >
            {notAvailableValue
              ? 'I don’t want to receive any job opportunities' //'Not Available'
              : handleDate(dateValue, 'MMMM dd, yyyy')}
          </h5>
        </Col>
      </Row>

      <Row className="ms-2">
        {!reduced && (
          <Col className="mt-1" style={{ width: 'fit-content', flex: 'none' }}>
            <Button
              type="button"
              variant="info"
              onClick={handleConfirm}
              disabled={loading}
            >
              <span>Confirm</span>
            </Button>
          </Col>
        )}
        <Col className="mt-1" style={{ width: 'fit-content', flex: 'none' }}>
          <DateTimeInput
            noTime
            value={!notAvailableValue ? dateValue : ''}
            name="seafarer-availability"
            handleDateChange={handleAvailabilityChange}
            disabled={loading}
            customInput={
              <Button type="button" variant="info" onClick={() => {}}>
                <span>Edit Availability</span>
              </Button>
            }
          />
        </Col>
      </Row>
      <Row className="mt-3 ms-2 mb-3">
        <Col>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="I don’t want to receive any job opportunities"
              checked={notAvailableValue}
              onChange={(e) => handleCheckboxToggle(e.target.checked)}
              disabled={loading}
            />
          </Form.Group>
        </Col>
      </Row>
    </GenericModal>
  );
};

export default CheckAvailabilityModel;
