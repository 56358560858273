import React, { createContext, useState, useEffect } from 'react';

const PageTitleContext = createContext({
  pageTitle: '',
  setPageTitle: () => {}
});

const PageTitle = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');
  const value = { pageTitle, setPageTitle };

  return (
    <PageTitleContext.Provider value={value}>{children}</PageTitleContext.Provider>
  );
};

// export default PageTitle
export { PageTitleContext, PageTitle };
