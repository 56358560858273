import { DashboardActionTypes } from './constants';

const INIT_STATE = {
  profileStrength: {},
  achievement: {},
  availability: {},
  seafarerData: {},
  lifeCycleStage: '',
  seafarerActivityLevel: 2,
  disembarkationDateChangeTimestamp: 0
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA_SUCCEEDED: {
      return {
        ...state,
        ...action.payload
      };
    }
    case DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA_FAILED: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default:
      return state;
  }
};

export default Dashboard;
