/* eslint-disable no-shadow */
import api from './api';
import { LRUCache } from 'lru-cache';

const options = {
  max: 5,
  maxSize: 500000,
  maxAge: 1000 * 60 * 60 * 24,
  sizeCalculation: (value, key) => {
    return 1;
  }
};

const cache = new LRUCache(options);

const profileApi = (api) => {
  const fetchProfile = ({ headers }) => {
    const url = '/subProfile/me/';
    const config = {
      headers
    };

    return Promise.all([api.get(url, config), fetchProfileTemplate({ headers })]);
  };

  const fetchBasicData = ({ headers }) => {
    const url = '/subProfile/me/basic/';
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const fetchIsProfileChange = ({ headers }) => {
    const url = '/subProfile/me/isProfileChange/';
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const fetchIsFirstLogin = ({ headers }) => {
    const url = '/subProfile/me/isFirstLogin/';
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const fetchPublicProfile = ({ headers }) => {
    const url = '/subProfile/me/public';
    const config = {
      headers
    };
    return Promise.all([api.get(url, config), fetchProfileTemplate({ headers })]);
  };

  const fetchProfileTemplate = async ({ headers }) => {
    const url = '/subProfile/me/edit';

    const cachedResponse = cache.get(url);

    if (cachedResponse) {
      console.log('Respuesta obtenida de la caché.');
      return JSON.parse(JSON.stringify(cachedResponse));
    }

    console.log('Respuesta obtenida de la API.');

    const config = {
      headers
    };

    const response = await api.get(url, config);
    const responseClone = JSON.parse(JSON.stringify(response));
    cache.set(url, responseClone);
    return response;
  };

  const updateProfile = ({ headers, profileBody, params }) => {
    const url = '/subProfile/me/edit';
    const data = profileBody;
    return api.put({
      url,
      headers,
      data,
      params
    });
  };

  const requestProfileRemoval = ({ headers, body }) => {
    const url = '/profile/me/deleteProfile';
    const data = body;
    return api.put({ url, headers, data });
  };

  return {
    fetchProfile,
    fetchPublicProfile,
    fetchBasicData,
    fetchProfileTemplate,
    updateProfile,
    requestProfileRemoval,
    fetchIsProfileChange,
    fetchIsFirstLogin
  };
};

export default profileApi(api);
