/* eslint-disable no-shadow */
import api from './api';

const statusApi = (api) => {
  const endpoint = '/status';
  const getCurrentStatus = ({ headers, params }) => {
    const url = `${endpoint}/me`;
    const config = {
      headers,
      params
    };
    return api.get(url, config);
  };

  return {
    getCurrentStatus
  };
};

export default statusApi(api);
