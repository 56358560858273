export const StatusCodes = {
  DEFAULT: '',
  OK: 'OK',
  GENERAL_ERROR: 'GENERAL_ERROR',
  ALREADY_REACTED: 'ALREADY_REACTED',
  UNAUTHORIZED: 'UNAUTHORIZED'
};

export const GetStatusFromErrorCode = (errorCode) => {
  const ErrorCodes = {
    CR_ALREADY_REACTED: StatusCodes.ALREADY_REACTED,
    UNAUTHORIZED: StatusCodes.UNAUTHORIZED,
    BAD_REQUEST: StatusCodes.GENERAL_ERROR
  };

  return ErrorCodes[errorCode] || StatusCodes.GENERAL_ERROR;
};
