/* eslint-disable no-shadow */
import api from './api';

const hubspotApi = (api) => {
  const baseUrl = '/hsProp';
  const setAppInstallation = ({ headers, formData = {} }) => {
    const url = `${baseUrl}/appInstallation`;
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  const updateLastSeaServiceDate = ({ headers, formData = {} }) => {
    const url = `${baseUrl}/lastSeaServiceUpdate`;
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  return {
    setAppInstallation,
    updateLastSeaServiceDate
  };
};

export default hubspotApi(api);
