import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { DashboardActionTypes } from './constants';
import {
  updateDashboardBasicDataSucceeded,
  updateDashboardBasicDataFailed
} from './actions';
import dashboardApi from '../../core/services/dashboardApi';

function* fetchDashboardBasicData(action) {
  try {
    const dashboardDetailsAPIResp = yield call(
      dashboardApi.fetchDashboardDetails,
      action.payload
    );
    const dashboardBasicData = {
      profileStrength: dashboardDetailsAPIResp?.data?.profileStrength || {},
      achievement: dashboardDetailsAPIResp?.data?.achievement || {},
      availability: dashboardDetailsAPIResp?.data?.availability || {},
      seafarerData: dashboardDetailsAPIResp?.data?.seafarerData || {},
      lifeCycleStage: dashboardDetailsAPIResp?.data?.lifeCycleStage || '',
      seafarerActivityLevel:
        dashboardDetailsAPIResp?.data?.seafarerActivityLevel || 0,
      disembarkationDateChangeTimestamp:
        dashboardDetailsAPIResp?.data?.disembarkationDateChangeTimestamp || 0
    };

    yield put(updateDashboardBasicDataSucceeded(dashboardBasicData));
  } catch (e) {
    yield put(updateDashboardBasicDataFailed());
  }
}

function* updateDashboardBasicDataSaga() {
  yield takeLatest(
    DashboardActionTypes.UPDATE_DASHBOARD_BASIC_DATA,
    fetchDashboardBasicData
  );
}

function* DashboardSaga() {
  yield all([fork(updateDashboardBasicDataSaga)]);
}

export default DashboardSaga;
