/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { AccountContext } from '../../contexts/Accounts';
import AccountLayout from '../../layouts/Authentication/AccountLayout';

const ConfirmUser = () => {
  const { handleConfirmUser, resendConfirmationCode } = useContext(AccountContext);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [success, setSuccess] = useState(false);
  const [confirmationCodeRequested, setConfirmationCodeRequested] = useState(false);

  const url = new URL(window.location.href);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const clientId = url.searchParams.get('client_id');
    const userName = url.searchParams.get('user_name');
    const confirmationCode = url.searchParams.get('confirmation_code');
    if (userName && confirmationCode) {
      const decodedUserName = decodeURIComponent(userName);
      handleConfirmUser({ userName: decodedUserName, confirmationCode })
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          if (err.code === 'NotAuthorizedException') {
            setSuccess(true);
          } else {
            setError(err.code);
          }
        });
    } else {
      setError('GeneralError');
    }
  }, []);

  const requestConfirmationCode = () => {
    const userName = url.searchParams.get('user_name');
    const decodedUserName = decodeURIComponent(userName);
    setConfirmationCodeRequested(true);
    if (decodedUserName) {
      resendConfirmationCode(decodedUserName.toLowerCase());
    }
    setError('');
    setInfo(
      'We have sent a new confirmation email. Please check your inbox and click “Confirm Registration” in the last message you received.'
    );
  };

  const getErrorMessage = (errorCode) => {
    if (errorCode === 'ExpiredCodeException') {
      return (
        <>
          <Alert variant="danger" className="my-2">
            The link you have used to confirm your account is expired. Please click
            on the button below and we will send you a new confirmation email.
          </Alert>
          <p className="text-center mt-3">
            <Button
              variant="danger"
              className="w-100"
              onClick={requestConfirmationCode}
              disabled={confirmationCodeRequested}
            >
              Send New Confirmation Email
            </Button>
          </p>
        </>
      );
    } else if (errorCode === 'CodeMismatchException') {
      return (
        <>
          <Alert variant="danger" className="my-2">
            The link you have used to confirm your account is invalid. Please make
            sure that you have clicked in the last message you received.
          </Alert>
          <p className="text-center mt-3">
            <Button
              variant="danger"
              className="w-100"
              onClick={requestConfirmationCode}
              disabled={confirmationCodeRequested}
            >
              Send New Confirmation Email
            </Button>
          </p>
        </>
      );
    } else if (errorCode === 'NotAuthorizedException') {
      return (
        <>
          <Alert variant="info" className="my-2">
            Your account seems to be already CONFIRMED. Please try to login.
          </Alert>
          <p className="text-center mt-3">
            <Link className="btn btn-primary" to="/login">
              Go to Login
            </Link>
          </p>
        </>
      );
    } else {
      return (
        <>
          <Alert variant="danger" className="my-2">
            We couldn&apos;t confirm your account. Please try again.
          </Alert>
        </>
      );
    }
  };

  return (
    <AccountLayout>
      {success ? (
        <div className="text-start w-100 m-auto">
          <h1 className="display-1 text-primary text-start mt-0 fw-bold">
            Welcome!
          </h1>
          <p className="text-muted text-start mb-4">
            Your account is now activated and you can log in. In the next step you
            have to enter some personal details.
          </p>
          <p className="text-center">
            <Link className="btn btn-primary" to="/login">
              Go to Login
            </Link>
          </p>
        </div>
      ) : (
        <div className="text-center m-auto">
          <h4 className="text-dark-50 text-center mt-4 fw-bold">
            Confirm registration
          </h4>
          {info && (
            <Alert variant="info" className="my-2">
              {info}
            </Alert>
          )}
          {error && getErrorMessage(error)}
          {!error && !info && (
            <p className="text-muted mb-4">
              Please wait while we confirm your registration.
            </p>
          )}
        </div>
      )}
    </AccountLayout>
  );
};

export default ConfirmUser;
