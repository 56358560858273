import React, { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import VerticalForm from '../../components/VerticalForm';
import FormInput from '../../components/FormInput';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import { AccountContext } from '../../contexts/Accounts';

const NewPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { handleNewPassword } = useContext(AccountContext);
  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState(false);

  const { state } = location;

  const schemaResolver = yupResolver(
    Yup.object().shape({
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])\S{6,99}$/,
          'Must contain 6 characters, one uppercase, one lowercase, one number and one special character'
        )
        .required('Please enter Password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      )
    })
  );

  const onSubmit = (values) => {
    setLoading(true);
    setSubmitError('');

    handleNewPassword(values.password, { ...state })
      .then(() => {
        const urlParams = new URLSearchParams(location.search.replace('?', ''));
        const from = urlParams.get('from');
        history.push(from || '/');
      })
      .catch(() => {
        setSubmitError('Something went wrong. Please contact us.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const BottomLink = () => {
    return (
      <Row className="mt-3">
        <Col className="text-center">
          <p className="text-muted">
            Back to{' '}
            <Link to="/login" className="text-info ms-1">
              <b>Log In</b>
            </Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-start w-100 m-auto">
        <h1 className="display-1 text-primary text-start mt-0 fw-bold">
          New password
        </h1>
        <p className="text-muted text-start mb-4">Please create a new password.</p>
      </div>

      {submitError && (
        <Alert variant="danger" className="my-2">
          {submitError}
        </Alert>
      )}

      <VerticalForm onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{}}>
        <FormInput
          label="Password"
          type="password"
          name="password"
          placeholder="Enter your password"
          containerClass="mb-3"
        />
        <FormInput
          label="Confirm password"
          type="password"
          name="confirmPassword"
          placeholder="Confirm your password"
          containerClass="mb-3"
        />

        <div className="mb-3 mb-0 text-center">
          <Button
            variant="danger"
            className="w-100"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />
            ) : (
              'Reset'
            )}
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
};

export default NewPassword;
