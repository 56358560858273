import { FeatureFlagsActionTypes } from './constants';

const INIT_STATE = {
  newLikesLogic: false
};

const FeatureFlags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FeatureFlagsActionTypes.NEW_LIKES_LOGIC:
      return {
        ...state,
        newLikesLogic: action.payload
      };
    default:
      return state;
  }
};

export default FeatureFlags;
