// @flow
import React, { Suspense, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import * as Sentry from '@sentry/react';
import { Steps } from 'intro.js-react';
import { Statuses } from '../../core/config/Statuses';

// Context
import { HelpContext } from '../../contexts/Help';
import { AccountContext } from '../../contexts/Accounts';
import { UserStatusContext } from '../../contexts/UserStatus';
import { PageTitle } from '../../contexts/PageTitle';

// constants
import * as layoutConstants from '../../constants/layout';

// utils
import { changeBodyAttribute } from '../../core/utils/layout';

// APIs
import eventApi from '../../core/services/eventApi';

// Redux
import { useDispatch } from 'react-redux';
import { showLeftSidebar, hideLeftSidebar } from '../../redux/actions';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className="" />;

const VerticalLayout = ({ status, children }) => {
  const dispatch = useDispatch();
  const stepsRef = useRef();
  const { user } = useContext(AccountContext);
  const { userStatus } = useContext(UserStatusContext);

  const { leftSideBarTheme, leftSideBarType, layoutWidth, isSideBarOpen } =
    useSelector((state) => ({
      layoutWidth: state.Layout.layoutWidth,
      leftSideBarTheme: state.Layout.leftSideBarTheme,
      leftSideBarType: state.Layout.leftSideBarType,
      isSideBarOpen: state.Layout.isSideBarOpen
    }));

  /**
   * Help context
   */
  const { enabled, setEnabled, steps } = useContext(HelpContext);

  const markIntroAsCompletedLS = (introClosed = true) => {
    try {
      const introCompletedLS = localStorage.getItem('INTRO_COMPLETED');
      if (!introCompletedLS) {
        localStorage.setItem('INTRO_COMPLETED', 'true');
        if (userStatus === Statuses.CONFIRMED && !introClosed) {
          eventApi
            .tutorialFinish({
              headers: {
                Authorization: user.session.getIdToken().getJwtToken()
              }
            })
            .then();
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onGuideExit = (status) => {
    if (status === undefined) return;

    markIntroAsCompletedLS();
    setEnabled(false);
  };

  const onComplete = () => {
    markIntroAsCompletedLS(false);
    setEnabled(false);
  };

  const onChange = (index) => {
    try {
      const introCompleted = localStorage.getItem('INTRO_COMPLETED');
      if (!introCompleted && userStatus === Statuses.CONFIRMED && index === 1) {
        eventApi
          .tutorialNext({
            headers: {
              Authorization: user.session.getIdToken().getJwtToken()
            }
          })
          .then();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /*
   * layout defaults
   */
  useEffect(() => {
    changeBodyAttribute('data-layout', layoutConstants.LAYOUT_VERTICAL);
  }, []);

  useEffect(() => {
    changeBodyAttribute('data-layout-mode', layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    changeBodyAttribute('data-leftbar-theme', leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType);
  }, [leftSideBarType]);

  /*
   * Force menu closed on mobile as initial state as workaround to solve
   * problem with sidebar.
   */
  useEffect(() => {
    if (window.innerWidth < 768) {
      dispatch(hideLeftSidebar());
    }
  }, []);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    if (isSideBarOpen) {
      dispatch(hideLeftSidebar());
    } else {
      dispatch(showLeftSidebar());
    }
  };

  useEffect(() => {
    if (document.body) {
      if (isSideBarOpen) {
        document.body.classList.add('sidebar-enable');
      } else {
        document.body.classList.remove('sidebar-enable');
      }
    }
  }, [isSideBarOpen]);

  const onAfterChange = (newStepIndex) => {
    if (
      steps &&
      steps.length > 0 &&
      newStepIndex >= 0 &&
      newStepIndex < steps.length
    ) {
      const selectorName = steps[newStepIndex].element;
      const element = document.querySelector(selectorName);

      if (!element) stepsRef.current.introJs.nextStep();
    }
  };

  return (
    <>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={0}
        onExit={onGuideExit}
        onComplete={onComplete}
        onChange={onChange}
        options={{
          disableinteraction: false,
          doneLabel: 'Finish'
        }}
        onAfterChange={onAfterChange}
        ref={stepsRef}
      />
      <div className="wrapper">
        <PageTitle>
          <Suspense fallback={loading()}>
            <LeftSidebar isLight={false} hideUserProfile status={status} />
          </Suspense>
          <div className="content-page">
            <div className="content mt-4">
              <Suspense fallback={loading()}>
                <Topbar openLeftMenuCallBack={openMenu} hideLogo />
              </Suspense>
              <Container fluid className="mt-3">
                <Suspense fallback={loading()}>{children}</Suspense>
              </Container>
            </div>

            <Suspense fallback={loading()}>
              <Footer />
            </Suspense>
          </div>
        </PageTitle>
      </div>
    </>
  );
};
export default VerticalLayout;
