import handleDate from './get-ddMMyyy-date';
import differenceInDays from 'date-fns/differenceInDays';

export const checkDaysDifference = (date) => {
  const lastUpdatedDate = handleDate(new Date(Number(date)), 'yyyy , M, dd');
  const currentDate = handleDate(new Date() / 1000, 'yyyy , M, dd');
  const dateDifferenceInDays = differenceInDays(
    new Date(currentDate),
    new Date(lastUpdatedDate)
  );
  return dateDifferenceInDays;
};
