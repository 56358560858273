import { format } from 'date-fns';

const handleDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (date && Number(date)) {
    const localTimeValue = new Date(date * 1000);
    const shiftedSeconds =
      localTimeValue.getUTCHours() * 3600 +
      localTimeValue.getUTCMinutes() * 60 +
      localTimeValue.getUTCSeconds();
    if (shiftedSeconds > 0) {
      return format(localTimeValue, dateFormat);
    }
    return format(
      new Date(
        localTimeValue.getUTCFullYear(),
        localTimeValue.getUTCMonth(),
        localTimeValue.getUTCDate()
      ),
      dateFormat
    );
  }

  return '-';
};

export default handleDate;
