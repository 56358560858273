/* eslint-disable no-shadow */
import api from './api';

const eventApi = (api) => {
  const tutorialFinish = ({ headers, formData = {} }) => {
    const url = '/event/tutorialFinish';
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  const tutorialNext = ({ headers, formData = {} }) => {
    const url = '/event/tutorialNext';
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  const tutorialInitialize = ({ headers, formData = {} }) => {
    const url = '/event/tutorialInitialize';
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  return {
    tutorialFinish,
    tutorialNext,
    tutorialInitialize
  };
};

export default eventApi(api);
