import api from './api';

const cvDataExtractorApi = (api) => {
  const uploadFile = ({ headers, formData }) => {
    const url = 'cv/upload';
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  const scanUploadFile = ({ headers, formData, scan }) => {
    const url = `cv/upload?scan=${scan}`;
    const config = {
      ...headers
    };
    const data = formData;
    return api.post(url, config, data);
  };

  const downloadExtractedData = ({ headers }) => {
    const url = 'cv/download';
    const config = {
      headers
    };
    return api.get(url, config);
  };

  return {
    uploadFile,
    downloadExtractedData,
    scanUploadFile
  };
};

export default cvDataExtractorApi(api);
