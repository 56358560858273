import React, { useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import {
  formatPhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber
} from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { AccountContext } from '../../../contexts/Accounts';
import VerticalForm from '../../../components/VerticalForm';
import FormInput from '../../../components/FormInput';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Wizard, Steps, Step } from 'react-albus';
import {
  hsIdentifyUserNewSignUp,
  hsTrackPageView
} from '../../../core/utils/hubspot';
import { Officers, Ratings } from '../../../core/config/OfficersRatings';
import '../components/PasswordHint.scss';
import { isDomainValid } from '../../../core/utils/domain-validator';
import { REGISTRATION_FORM_BKP } from '../../../constants';
import { Alert } from 'react-bootstrap';

const WizardRegistration = ({
  ranks = [],
  shipTypes = [],
  initialValues = {},
  firstStepDone = false,
  onError = () => {},
  referral = ''
}) => {
  const { handleSignUp } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const history = useHistory();

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  // Custom validator
  Yup.addMethod(Yup.string, 'emailDomain', function (args) {
    const { message } = args;
    return this.test('test-email-domain', message, function (value) {
      const { path, createError } = this;
      return isDomainValid(value) || createError({ path, message: message });
    });
  });

  const handleChange = (value) => {
    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*[0-9])');
    const special = new RegExp(
      /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])\S{1,}$/
    );
    const length = new RegExp('(?=.{6,})');
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  const [selectedRank, setSelectedRank] = useState(initialValues?.currentRank || '');
  const openRanks = [
    'CHIEF_ENGINEER',
    '2ND_ENGINEER',
    'ELECTRO-TECHNICAL_OFFICER_(ETO)'
  ];
  const handleRankChange = (event) => {
    setSelectedRank(event?.target?.value || '');
  };

  useEffect(() => {
    if (formValues.email) {
      hsIdentifyUserNewSignUp(formValues, 1, referral);
      hsTrackPageView('/sign-up');
    }
  }, [formValues]);

  const tandcLabel = (
    <div className="tandc-label">
      I have read and accept the{' '}
      <a href="https://www.go-turtle.com/privacy-policy/">Privacy Policy</a> and the{' '}
      <a href="https://www.go-turtle.com/general-terms-conditions/">
        Terms and Conditions
      </a>
      .
    </div>
  );

  const onSubmit = ({ email, phoneNumber, ...values }) => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      const valuesObj = {
        ...values,
        currency: 'USD',
        countryCode: getCountryCallingCode(parsedPhoneNumber.country),
        phoneNumber: formatPhoneNumber(phoneNumber),
        email: email.toLowerCase()
      };
      setFormValues(valuesObj);
      setLoading(true);
      handleSignUp(valuesObj)
        .then(() => {
          if (valuesObj.currentRank && Officers.includes(valuesObj.currentRank)) {
            history.push('/registration-complete?rankType=officer');
          } else if (
            valuesObj.currentRank &&
            Ratings.includes(valuesObj.currentRank)
          ) {
            history.push('/registration-complete?rankType=rating');
          } else {
            history.push('/registration-complete?rankType=other');
          }
        })
        .catch((err) => {
          onError(err.message);
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      if (!parsedPhoneNumber || !parsedPhoneNumber.country) {
        toast.error('Please enter a valid phone number with country code');
        onError('Please enter a valid phone number with country code');
      } else {
        toast.error('Registration failed. Please try again later');
        onError('Registration failed. Please try again later');
        Sentry.captureException(error);
      }
    }
  };

  const schemaResolver1 = yupResolver(
    Yup.object().shape({
      currentRank: Yup.string().required('Please select your rank'),
      email: Yup.string()
        .email('Please enter a valid email')
        .emailDomain({ message: 'Please enter a valid email' })
        .required('Please enter a valid email'),
      tandc: Yup.boolean()
        .required('You must agree to the terms and conditions')
        .oneOf([true], 'You must agree to the terms and conditions')
    })
  );

  const schemaResolver2 = yupResolver(
    Yup.object().shape({
      preferredVessel: Yup.string().required('Please select Preferred vessel'),
      salary: Yup.number()
        .typeError('You must enter a value with only numbers')
        .required('Please enter Salary'),
      firstName: Yup.string()
        .required('Please enter First name')
        .min(2, 'Must be 2 characters or more')
    })
  );

  const schemaResolver3 = yupResolver(
    Yup.object().shape({
      phoneNumber: Yup.string().required('Please enter Phone number'),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])\S{6,99}$/,
          'Must contain 6 characters, one uppercase, one lowercase, one number and one special character'
        )
        .required('Please enter Password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      )
    })
  );

  const saveToLocalStorage = (values) => {
    try {
      localStorage.setItem(REGISTRATION_FORM_BKP, JSON.stringify(values));
    } catch (error) {}
  };

  const onStepCompleted = (values = {}, next = () => {}) => {
    setFormValues(values);
    saveToLocalStorage(values);
    next();
  };

  const handleOnNext = ({ step, push }) => {
    if (!step?.id && firstStepDone) {
      step = {
        id: 'signup1'
      };
    }

    switch (step.id) {
      case 'signup1':
        push('signup2');
        break;
      case 'signup2':
        push('signup3');
        break;
      default:
        push('signup1');
        break;
    }

    return;
  };

  const handleOnPrevious = ({ step, push }) => {
    switch (step.id) {
      case 'signup2':
        push('signup1');
        break;
      case 'signup3':
        push('signup2');
        break;
      default:
        push('signup1');
        break;
    }

    return;
  };
  return (
    <Wizard
      onNext={handleOnNext}
      render={({ step, steps }) => (
        <>
          <ProgressBar
            variant="danger"
            now={((steps.indexOf(step) + 1) / steps.length) * 100}
            className="mb-3 progress-sm d-none d-md-block"
          />

          <Steps>
            <Step
              id="signup1"
              render={({ next }) => (
                <>
                  <div className="text-start w-100">
                    <div className="text-secondary mb-2">
                      <span className="display-6 fw-bold">1</span>
                      <span className="fw-bold">/3</span>
                    </div>
                    <h1 className="display-1 text-primary text-start mt-0 fw-bold">
                      Register Now!
                    </h1>
                    <p className="text-muted text-start mb-4">
                      Register now to connect directly with ship owners and be found
                      by your next employer! Fair recruitment process and always free
                      of charge. TURTLE protects your data - No one will see your
                      details until you actively allow it.
                    </p>
                  </div>

                  <VerticalForm
                    onSubmit={({ ...values }) =>
                      onStepCompleted({ ...formValues, ...values }, next)
                    }
                    resolver={schemaResolver1}
                    defaultValues={{
                      email: formValues.email || '',
                      currentRank: formValues.currentRank || '',
                      tandc: formValues.tandc || false
                    }}
                  >
                    <FormInput
                      label="Rank"
                      type="select"
                      name="currentRank"
                      containerClass="mb-3"
                      defaultValue=""
                      onChange={handleRankChange}
                    >
                      <option hidden disabled value="">
                        Select an option
                      </option>
                      {ranks.map((option) => (
                        <option key={option.id} value={option.type}>
                          {option.label}
                        </option>
                      ))}
                    </FormInput>
                    {openRanks.includes(selectedRank) && (
                      <Alert variant="info">
                        <i className="uil-comment-exclamation me-2" />
                        Nice, we have open positions for your rank! 🎉
                      </Alert>
                    )}
                    <FormInput
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      containerClass="mb-3"
                    />
                    <FormInput
                      label={tandcLabel}
                      type="checkbox"
                      name="tandc"
                      containerClass="mb-3 text-muted"
                    />
                    <Stack gap="1">
                      <Button variant="danger" type="submit">
                        Continue
                      </Button>
                    </Stack>
                  </VerticalForm>
                </>
              )}
            />
            <Step
              id="signup2"
              render={({ next, push, step }) => (
                <>
                  <div className="text-start w-100">
                    <div className="text-secondary mb-2">
                      <span className="display-6 fw-bold">2</span>
                      <span className="fw-bold">/3</span>
                    </div>
                    <h1 className="display-1 text-primary text-start mt-0 fw-bold">
                      Hi!
                    </h1>
                    <p className="text-muted text-start mb-4">
                      Let us know more about your conditions for your future job.
                    </p>
                  </div>
                  <VerticalForm
                    onSubmit={({ ...values }) =>
                      onStepCompleted({ ...formValues, ...values }, next)
                    }
                    resolver={schemaResolver2}
                    defaultValues={{
                      preferredVessel: formValues.preferredVessel || '',
                      salary: formValues.salary || '',
                      firstName: formValues.firstName || ''
                    }}
                  >
                    <FormInput
                      label="Preferred Vessel Type"
                      type="select"
                      name="preferredVessel"
                      containerClass="mb-3"
                      defaultValue=""
                    >
                      <option hidden disabled value="">
                        Select an option
                      </option>
                      {shipTypes.map((option) => (
                        <option key={option.id} value={option.type}>
                          {option.label}
                        </option>
                      ))}
                    </FormInput>
                    <FormInput
                      label="Salary Expectation (USD)"
                      type="text"
                      name="salary"
                      placeholder="Enter your salary expectation"
                      containerClass="mb-3"
                    />
                    <FormInput
                      label="First Name"
                      type="text"
                      name="firstName"
                      placeholder="Enter your first name"
                      containerClass="mb-3"
                    />
                    <Stack gap="1">
                      <Button variant="danger" type="submit">
                        Continue
                      </Button>
                      <Button
                        onClick={() => handleOnPrevious({ step, push })}
                        variant="outline-danger"
                      >
                        Previous
                      </Button>
                    </Stack>
                  </VerticalForm>
                </>
              )}
            />
            <Step
              id="signup3"
              render={({ step, push }) => (
                <>
                  <div className="text-start w-100 mt-3">
                    <div className="text-secondary mb-2">
                      <span className="display-6 fw-bold">3</span>
                      <span className="fw-bold">/3</span>
                    </div>
                    <h1 className="display-1 text-primary text-start mt-0 fw-bold">
                      {formValues?.firstName}, you are almost done!
                    </h1>
                  </div>
                  <VerticalForm
                    onSubmit={({ ...values }) =>
                      onSubmit({ ...formValues, ...values })
                    }
                    resolver={schemaResolver3}
                    defaultValues={{}}
                  >
                    <FormInput
                      label="Phone Number"
                      type="tel"
                      name="phoneNumber"
                      placeholder="+00 0000 0000 000"
                      containerClass="mb-3"
                      international
                    />
                    <FormInput
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      containerClass="mb-3"
                      onChange={(e) => handleChange(e.target.value)}
                    />

                    <div className="tracker-box mb-3">
                      <div
                        className={lowerValidated ? 'validated' : 'not-validated'}
                      >
                        {lowerValidated ? (
                          <span className="green">
                            <i className="uil-check-circle" />
                            &nbsp;
                          </span>
                        ) : (
                          <span className="list-icon">
                            <i className="uil-circle" />
                            &nbsp;
                          </span>
                        )}
                        At least one lowercase letter
                      </div>
                      <div
                        className={upperValidated ? 'validated' : 'not-validated'}
                      >
                        {upperValidated ? (
                          <span className="green">
                            <i className="uil-check-circle" />
                            &nbsp;
                          </span>
                        ) : (
                          <span className="list-icon">
                            <i className="uil-circle" />
                            &nbsp;
                          </span>
                        )}
                        At least one uppercase letter
                      </div>
                      <div
                        className={numberValidated ? 'validated' : 'not-validated'}
                      >
                        {numberValidated ? (
                          <span className="green">
                            <i className="uil-check-circle" />
                            &nbsp;
                          </span>
                        ) : (
                          <span className="list-icon">
                            <i className="uil-circle" />
                            &nbsp;
                          </span>
                        )}
                        At least one number
                      </div>
                      <div
                        className={specialValidated ? 'validated' : 'not-validated'}
                      >
                        {specialValidated ? (
                          <span className="green">
                            <i className="uil-check-circle" />
                            &nbsp;
                          </span>
                        ) : (
                          <span className="list-icon">
                            <i className="uil-circle" />
                            &nbsp;
                          </span>
                        )}
                        At least one special character
                      </div>
                      <div
                        className={lengthValidated ? 'validated' : 'not-validated'}
                      >
                        {lengthValidated ? (
                          <span className="green">
                            <i className="uil-check-circle" />
                            &nbsp;
                          </span>
                        ) : (
                          <span className="list-icon">
                            <i className="uil-circle" />
                            &nbsp;
                          </span>
                        )}
                        At least 6 characters
                      </div>
                    </div>

                    <FormInput
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      containerClass="mb-3"
                    />
                    <Stack gap="1">
                      <Button variant="danger" type="submit" disabled={loading}>
                        {loading ? (
                          <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />
                        ) : (
                          'Register'
                        )}
                      </Button>
                      <Button
                        onClick={() => handleOnPrevious({ step, push })}
                        variant="outline-danger"
                      >
                        Previous
                      </Button>
                    </Stack>
                  </VerticalForm>
                </>
              )}
            />
          </Steps>
        </>
      )}
    />
  );
};

export default WizardRegistration;
