import React from 'react';

import {
  RESTRICTED_ACCESS,
  FULL_ACCESS,
  NO_ACCESS,
  SALARY_BENCHMARK_ACCESS,
  SALARY_BENCHMARK_RESTRICTED_ACCESS,
  CR_ACCESS
} from './Statuses';

const Dashboard = React.lazy(() => import('../../pages/Dashboard'));
const Vacancies = React.lazy(() => import('../../pages/vacancies'));
const Profile = React.lazy(() => import('../../pages/Profile'));
const PublicProfile = React.lazy(() => import('../../pages/PublicProfile'));
const CompaniesList = React.lazy(() => import('../../pages/CompaniesList'));
const Interviews = React.lazy(() => import('../../pages/Interviews'));
const Jobs = React.lazy(() => import('../../pages/Jobs'));
const CompanyProfile = React.lazy(() => import('../../pages/CompanyProfile'));
const Settings = React.lazy(() => import('../../pages/Settings'));
const NotAvailable = React.lazy(() => import('../../pages/NotAvailable'));
const ComingSoonFeature = React.lazy(() => import('../../pages/ComingSoonFeature'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const SalaryCheck = React.lazy(() => import('../../pages/SalaryCheck'));
const RestrictedAccess = React.lazy(() =>
  import('../../pages/RestrictedAccessFeature')
);
const Airfocus = React.lazy(() => import('../../pages/airfocus'));
const Achievements = React.lazy(() => import('../../pages/Achievements'));

const privateRoutes = [
  {
    key: 'dashboard',
    component: Dashboard,
    path: '/dashboard',
    label: 'Overview',
    exact: true,
    icon: 'uil-home-alt',
    isMenuLink: true,
    url: '/dashboard',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'achievements',
    component: Achievements,
    path: '/achievements',
    label: 'Achievements',
    exact: true,
    icon: 'uil-trophy',
    isMenuLink: true,
    url: '/achievements',
    isTitle: false,
    badge: { variant: 'danger', text: 'NEW' },
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'vacancies',
    component: Vacancies,
    path: '/vacancies',
    label: 'Vacancies',
    exact: true,
    icon: 'uil-file-plus-alt',
    isMenuLink: true,
    url: '/vacancies',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'profile',
    component: Profile,
    path: '/profile',
    label: 'Profile',
    exact: true,
    icon: 'uil-user-circle',
    isMenuLink: true,
    url: '/profile',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'public-profile',
    component: PublicProfile,
    path: '/profile/public',
    label: 'Public Profile',
    exact: true,
    icon: 'uil-home-alt',
    isMenuLink: false,
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'companies',
    component: CompaniesList,
    path: '/companies',
    label: 'Companies',
    exact: true,
    icon: 'uil-ship',
    isMenuLink: true,
    url: '/companies',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'salarycheck',
    component: SalaryCheck,
    path: '/salarycheck',
    label: 'Salarycheck',
    exact: true,
    icon: 'uil-dollar-sign',
    isMenuLink: true,
    url: '/salarycheck',
    isTitle: false,
    permission: [...SALARY_BENCHMARK_ACCESS]
  },
  {
    key: 'salarycheck',
    component: RestrictedAccess,
    path: '/salarycheck',
    label: 'Salarycheck',
    exact: true,
    icon: 'uil-dollar-sign',
    isMenuLink: true,
    url: '/salarycheck',
    isTitle: false,
    permission: [...SALARY_BENCHMARK_RESTRICTED_ACCESS]
  },
  {
    key: 'trainings',
    path: 'https://www.go-turtle.com/sftrainings',
    label: 'Trainings',
    exact: true,
    icon: 'uil-book-open',
    isMenuLink: true,
    isMenuExternal: true,
    target: '_blank',
    url: 'https://www.go-turtle.com/sftrainings',
    isTitle: false,
    featureFlag: 'trainingsLink'
  },
  {
    key: 'contact-requests',
    component: Interviews,
    path: '/interviews',
    label: 'Contact Requests',
    exact: true,
    icon: 'uil-comments-alt',
    isMenuLink: true,
    url: '/interviews',
    isTitle: false,
    permission: [...CR_ACCESS]
  },
  {
    key: 'jobs',
    component: Jobs,
    path: '/jobs',
    label: 'Jobs',
    exact: true,
    icon: 'uil-file-copy-alt',
    isMenuLink: true,
    url: '/jobs',
    isTitle: false,
    permission: [...FULL_ACCESS]
  },
  {
    key: 'company-profile',
    component: CompanyProfile,
    path: '/company/:companyId',
    label: 'Company Profile',
    exact: true,
    isMenuLink: false,
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'settings',
    component: Settings,
    path: '/settings',
    label: 'Settings',
    exact: true,
    isMenuLink: true,
    url: '/settings',
    icon: 'uil-cog',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },
  {
    key: 'airfocus',
    component: Airfocus,
    path: '/airfocus',
    label: '',
    exact: true,
    icon: 'uil-home-alt',
    isMenuLink: false,
    url: '/airfocus',
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS]
  },

  {
    key: 'not-available',
    component: NotAvailable,
    path: '/not-available',
    label: 'Not Available',
    exact: true,
    icon: 'uil-phone-slash',
    isMenuLink: true,
    url: '/not-available',
    isTitle: false,
    permission: [...NO_ACCESS]
  },
  {
    key: 'not-found',
    component: NotFound,
    label: 'Not Found',
    isMenuLink: false,
    isTitle: false,
    permission: [...RESTRICTED_ACCESS, ...FULL_ACCESS, ...NO_ACCESS]
  }
];

export default privateRoutes;
