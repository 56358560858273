import React, { createContext } from 'react';
import useHelp from '../core/hooks/useHelp';

const HelpContext = createContext();

const HelpProvider = ({ children }) => {
  const { steps, enabled, setEnabled, setSteps } = useHelp();
  return (
    <HelpContext.Provider
      value={{
        steps,
        enabled,
        setEnabled,
        setSteps
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};

export { HelpContext, HelpProvider };
