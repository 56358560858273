/* eslint-disable no-shadow */
import axios from 'axios';

const axiosConfig = {
  baseURL: process.env.REACT_APP_PUBLIC_API
};

const axiosInstance = axios.create(axiosConfig);

const get = (url, config) => axiosInstance.get(url, config);
const put = ({ url, data }) => axiosInstance.put(url, data);

const publicApi = () => {
  const fetchRanks = () => {
    const url = '/rank';
    return get(url);
  };

  const fetchShipSegments = () => {
    const url = '/shipSegment';
    return get(url);
  };

  const rejectCR = ({ data }) => {
    const url = '/seafarer/contactRequest/reject';
    return put({ url: url, data: data });
  };

  const acceptCR = ({ data }) => {
    const url = '/seafarer/contactRequest/accept';
    return put({ url: url, data: data });
  };

  return {
    fetchRanks,
    fetchShipSegments,
    rejectCR,
    acceptCR
  };
};

export default publicApi();
