import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { withLDProvider, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const deviceType = process.env.REACT_APP_IS_PWA === 'true' ? 'PWA' : 'BROWSER';

Sentry.init({
  dsn: 'https://2f87bbd9527c42e881c838fdb4a7be06@o1084965.ingest.sentry.io/6130865',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
  ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],
  environment: process.env.REACT_APP_API.includes('dev')
    ? 'development'
    : 'production'
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    context: {
      kind: 'multi',
      user: {
        key: 'anonymous-user',
        name: 'anonymous',
        anonymous: true,
        profileType: 'SEAFARER'
      },
      device: {
        key: 'dev-1',
        platform: deviceType
      },
      application: {
        key: 'seafarer-portal',
        name: 'Seafarer Portal',
        version: '1.0.0'
      }
    }
  });

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
