import { yupResolver } from '@hookform/resolvers/yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';

import FormInput from '../../components/FormInput';
import VerticalForm from '../../components/VerticalForm';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import { AccountContext } from '../../contexts/Accounts';
import { CognitoErrors } from '../../core/config/Constants';
import Texts from '../../data/texts';

const ForgotPassword = () => {
  const history = useHistory();
  const { handleForgotPassword } = useContext(AccountContext);
  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    setSubmitError('');
    handleForgotPassword(values.email)
      .then(() => {
        history.push('/reset-password');
      })
      .catch((err) => {
        if (err && err.code === CognitoErrors.USER_NOT_FOUND) {
          setSubmitError(Texts.FORGOT_PASSWORD.USER_NOT_FOUND);
        } else {
          setSubmitError(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const schemaResolver = yupResolver(
    Yup.object().shape({
      email: Yup.string()
        .email('Please enter valid Email')
        .required('Please enter Email')
    })
  );

  const BottomLink = () => {
    return (
      <Row className="mt-3">
        <Col className="text-center">
          <p className="text-muted">
            Back to{' '}
            <Link to="/login" className="text-info ms-1">
              <b>Log In</b>
            </Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-start w-100 m-auto">
        <h1 className="display-1 text-primary text-start mt-0 fw-bold">
          Forgot your password?
        </h1>
        <p className="text-muted text-start mb-4">
          Enter your email address and we&apos;ll send you an email with a
          verification code that you can use to reset your password.
        </p>
      </div>

      {submitError && (
        <Alert variant="danger" className="my-2">
          {submitError}
        </Alert>
      )}

      <VerticalForm onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{}}>
        <Link to="/reset-password" className="text-muted float-end">
          <small>I have a verification code</small>
        </Link>
        <FormInput
          label="Email address"
          type="email"
          name="email"
          placeholder="Enter your email"
          containerClass="mb-3"
        />
        <div className="mb-3 mb-0 text-center">
          <Button
            variant="danger"
            className="w-100"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </VerticalForm>
    </AccountLayout>
  );
};

export default ForgotPassword;
