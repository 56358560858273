import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const CRAlreadyReacted = ({}) => {
  const history = useHistory();

  return (
    <div className="text-start w-100 m-auto">
      <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
        :)
      </h1>
      <div className="text-muted mb-4">
        <br />
        <strong>you have already used this link.</strong>
        <br />
        <br />
        <p className="text-start">
          The link is invalid. To make changes, please log in to the system using
          your credentials.
        </p>
        <br />
        <div className="mb-3 mb-0 text-center">
          <Button variant="danger" type="button" onClick={() => history.push('/')}>
            TO YOUR TURTLE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CRAlreadyReacted;
