import intersection from './intersection';
import isArrayWithLength from './is-array-with-length';

const getAllowedRoutes = (routes, status, featureFlags = {}, exclude = []) => {
  let preFilteredRoutes = routes.filter(({ permission, key }) => {
    if (exclude.includes(key)) return false;
    if (!permission) return true;
    if (!isArrayWithLength(permission)) return true;

    return intersection(permission, status).length;
  });

  // Filter by feature flags from LD
  return preFilteredRoutes.filter((route) => {
    const associatedFF = route.featureFlag;

    if (!associatedFF) return true;
    if (Object.keys(featureFlags).length > 0 && featureFlags[associatedFF])
      return true;

    return false;
  });
};

export default getAllowedRoutes;
