import * as prismic from '@prismicio/client';

export const repositoryName = process.env.REACT_APP_PRISMIC_REPOSITORY_NAME;

export const client = prismic.createClient(repositoryName, {
  accessToken: process.env.REACT_APP_PRISMIC_TOKEN,
  routes: [
    {
      type: 'seafarer_dashboard',
      path: '/dashboard'
    }
  ]
});
