const blacklistedDomains = [
  'eamil.com',
  'emil.com',
  'gaail.com',
  'gimal.com',
  'gmai.com',
  'gmai.lcom',
  'gmaiil.com',
  'gmail.cm',
  'gmail.co',
  'gmailc.om',
  'gmailco.m',
  'gmaill.com',
  'gmal.com',
  'gmaol.com',
  'gmaul.com',
  'gmial.com',
  'gmil.com',
  'gml.com',
  'gtmail.com',
  'homail.com',
  'hotmai.com',
  'hotmil.com',
  'outloo.com',
  'yaho.com'
];

export const isDomainValid = (email) => {
  for (const domain of blacklistedDomains) {
    if (email.endsWith(domain)) {
      return false;
    }
  }

  return true;
};
