/* eslint-disable no-undef */
import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserBasicInfo } from '../redux/actions';
import { updateDashboardBasicData } from '../redux/actions';
import { AccountContext } from '../contexts/Accounts';
import { ModalContext } from '../contexts/Modals';
import { SeaGoingExperienceContext } from '../contexts/SeaGoingExperience';
import getAllowedRoutes from '../core/utils/get-allowed-routes';
import PrivateRoutesConfig from '../core/config/PrivateRoutesConfig';
import MapAllowedRoutes from '../core/routes/MapAllowedRoutes';
import statusApi from '../core/services/statusApi';
import profileApi from '../core/services/profileApi';
import { UserStatusProvider } from '../contexts/UserStatus';
import { hsIdentifyUser } from '../core/utils/hubspot';
import Texts from '../data/texts';
import toast from 'react-hot-toast';
import Loader from './Loader';
import featureFlagsApi from '../core/services/featureFlagsApi';
import CheckAvailabilityModel from '../pages/Dashboard/components/CheckAvailabilityModel';
import CheckSeaServiceModal from '../pages/Dashboard/components/CheckSeaServiceModal';
import { checkDaysDifference } from '../core/utils/date-utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ProductFruits } from 'react-product-fruits';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getTime } from 'date-fns';
import WelcomeModal from '../pages/Dashboard/components/WelcomeModel';
import { ROUTE_KEY_ACHIEVEMENTS_CHECK } from '../constants/routeKeys';

const statuses = [
  'PROFILE_REQUESTED',
  'READY_FOR_MATCH',
  'HIRED',
  'READY_FOR_MATCH_REVIEW',
  'HIRED_REVIEW'
];

const deviceType = process.env.REACT_APP_IS_PWA === 'true' ? 'PWA' : 'BROWSER';

const PrivateRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const featureFlags = useFlags();
  const { welcomeProfile, showAchievements, showCvScan } = useFlags();
  const dispatch = useDispatch();
  const { userBasicInfo, dashboardBasicData } = useSelector((state) => ({
    userBasicInfo: state.User.userBasicInfo,
    dashboardBasicData: state.Dashboard
  }));
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState();
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [countdown, setCountdown] = useState(2);
  const { handleModal } = useContext(ModalContext);
  const { user, getSession } = useContext(AccountContext);
  const {
    showSeaGoingExperience,
    addSeaGoingExperience,
    updateSeaGoingExperience,
    handleShowSeaGoingExperience
  } = useContext(SeaGoingExperienceContext);
  const LDClient = useLDClient();
  const [userInfoPF, setUserInfoPF] = useState({
    username: '', // REQUIRED - any unique user identifier
    email: '',
    firstname: '',
    lastname: '',
    role: 'SEAFARER',
    props: {
      rank: '',
      vesselType: '',
      profileStatus: ''
    }
  });

  const [isProfileChanged, setIsProfileChanged] = useState(0);
  const [isFirstLogin, setIsFirstLogin] = useState(1);
  const [isShowWelcomePopup, setIsShowWelcomePopup] = useState(false);

  const goToLogin = () => {
    const fullPath = location.pathname + location.search + location.hash;
    const encodedFullPath = encodeURIComponent(fullPath === '/' ? '' : fullPath);
    history.push(`/login${encodedFullPath ? `?redirect=${encodedFullPath}` : ''}`);
  };

  const subscribeToNotifications = () => {
    try {
      if (user && user.attributes) {
        let email = user.attributes.find((attr) => attr.Name === 'email') || {};
        window.OneSignal = window.OneSignal || [];
        OneSignal.push(() => {
          OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
          OneSignal.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js';
          OneSignal.SERVICE_WORKER_UPDATER_PATH =
            'push/onesignal/OneSignalSDKWorker.js';
          OneSignal.setExternalUserId(email.Value || '');
          OneSignal.init({
            appId: '08cde0fd-7771-4253-a1d6-81a2f3c2bcb1'
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hsIdentifyCurrentUser = () => {
    try {
      if (user && user.attributes) {
        let email = user.attributes.find((attr) => attr.Name === 'email') || {};
        if (email.Value) {
          hsIdentifyUser(email.Value);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn && userBasicInfo?.status && user?.session) {
      const LDContext = {
        kind: 'multi',
        user: {
          key: user.session.idToken.payload.email,
          name: userBasicInfo.firstName,
          lastName: userBasicInfo.lastName,
          email: user.session.idToken.payload.email,
          rank: userBasicInfo.currentRank.value,
          vesselType: userBasicInfo.currentPreferredVessel.value,
          profileType: 'SEAFARER',
          profileStatus: userBasicInfo.status
        },
        device: {
          key: 'dev-1',
          platform: deviceType
        },
        application: {
          key: 'seafarer-portal',
          name: 'Seafarer Portal',
          version: '1.0.0'
        }
      };
      LDClient.identify(LDContext, null, () => {
        // console.log('feture flags available for new context');
      });

      setUserInfoPF({
        username: user.session.idToken.payload.email, // REQUIRED - any unique user identifier
        email: user.session.idToken.payload.email,
        firstname: userBasicInfo.firstName,
        lastname: userBasicInfo.lastName,
        role: 'SEAFARER',
        props: {
          rank: userBasicInfo.currentRank.value,
          vesselType: userBasicInfo.currentPreferredVessel.value,
          profileStatus: userBasicInfo.status
        }
      });
    }
  }, [user?.session, userBasicInfo, isLoggedIn]);

  useEffect(() => {
    getSession()
      .then(() => {
        setLoading(false);
        setIsLoggedIn(true);
        // subscribeToNotifications();
        hsIdentifyCurrentUser();
      })
      .catch(() => {
        goToLogin();
      });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);

      dispatch(
        updateDashboardBasicData({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          }
        })
      );

      const currentStatusAPICall = statusApi.getCurrentStatus({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      const basicDataAPICall = profileApi.fetchBasicData({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      const featureFlagsAPICall = featureFlagsApi.fetchFeaturesFlags({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      const profileTemplateAPICall = profileApi.fetchProfileTemplate({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      const isProfileChange = profileApi.fetchIsProfileChange({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      const isFirstLogin = profileApi.fetchIsFirstLogin({
        headers: {
          Authorization: user.session.getIdToken().getJwtToken()
        }
      });

      Promise.all([
        currentStatusAPICall,
        basicDataAPICall,
        featureFlagsAPICall,
        profileTemplateAPICall,
        isProfileChange,
        isFirstLogin
      ])
        .then((responseArray) => {
          if (responseArray.length === 6) {
            const currentStatusAPIResp = responseArray[0];
            const basicDataAPIResp = responseArray[1];
            const featureFlagsAPIResp = responseArray[2];
            const isProfileChangedResp = responseArray[4];
            const isFirstLoginResp = responseArray[5];

            const exclude = [];

            // if (!showAchievements) {
            //   exclude.push(ROUTE_KEY_ACHIEVEMENTS_CHECK);
            // }

            // Process currentStatusAPIResp
            if (currentStatusAPIResp.data && currentStatusAPIResp.data.status) {
              setLoading(false);
              setStatus(currentStatusAPIResp.data.status);
              setAllowedRoutes(
                getAllowedRoutes(
                  PrivateRoutesConfig,
                  currentStatusAPIResp.data.status,
                  featureFlags,
                  exclude
                )
              );
            } else {
              goToLogin();
            }

            // Process basicDataAPIResp
            const userBasicInfo = {
              profileId: basicDataAPIResp.data.profileId,
              firstName: basicDataAPIResp.data.firstName,
              lastName: basicDataAPIResp.data.lastName,
              seafarerPhoto: basicDataAPIResp.data.seafarerPhoto,
              status: currentStatusAPIResp.data.status,
              currentRank: basicDataAPIResp.data.currentRank,
              currentPreferredVessel: basicDataAPIResp.data.currentPreferredVessel,
              lastDisembarkationDate: basicDataAPIResp.data.lastDisembarkationDate
            };
            dispatch(updateUserBasicInfo(userBasicInfo));

            // Process featureFlagsAPIResp
            const flags = featureFlagsAPIResp?.data?.flags || [];
            setIsFirstLogin(isFirstLoginResp.data.count);
            setIsProfileChanged(isProfileChangedResp.data);

            if (
              isFirstLoginResp.data.count <= 1 ||
              isProfileChangedResp.data === 0
            ) {
              setIsShowWelcomePopup(true);
            }
          } else {
            goToLogin();
          }
        })
        .catch(() => {
          if (countdown > 0) {
            setTimeout(() => {
              setCountdown(countdown - 1);
            }, 7000);
          } else {
            toast.error(Texts.GENERAL_ERRORS.ERROR_CURRENT_SESSION);
            goToLogin();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isLoggedIn, countdown, user?.session]);

  useEffect(() => {
    if (isProfileChanged === 0 && welcomeProfile && isShowWelcomePopup) {
      handleModal(
        <WelcomeModal
          onCancel={() => {
            setIsShowWelcomePopup(false);
            handleModal(false);
          }}
          backdrop="static"
          keyboard={false}
          welcomeCVScanVersion={true}
        />
      );
    } else if (isFirstLogin <= 1 && !welcomeProfile && isShowWelcomePopup) {
      handleModal(
        <WelcomeModal
          onCancel={() => {
            handleModal(false);
            setIsShowWelcomePopup(false);
          }}
          backdrop="static"
          keyboard={false}
          welcomeCVScanVersion={false}
        />
      );
    }
  }, [isProfileChanged, isFirstLogin, welcomeProfile, isShowWelcomePopup]);

  useEffect(() => {
    if (
      status &&
      statuses.includes(status) &&
      checkDaysDifference(dashboardBasicData?.availability?.changeTimestamp) >= 14 &&
      !isShowWelcomePopup
    ) {
      handleShowSeaGoingExperience();
      handleModal(
        <CheckAvailabilityModel
          onCancel={() => {
            handleModal(false);
            handleShowSeaGoingExperience();
            dispatch(
              updateDashboardBasicData({
                headers: {
                  Authorization: user.session.getIdToken().getJwtToken()
                }
              })
            );
          }}
          data={dashboardBasicData?.availability}
          firstName={dashboardBasicData?.seafarerData.firstName}
          backdrop="static"
          keyboard={false}
        />
      );
    }
  }, [dashboardBasicData, status, isShowWelcomePopup]);

  useEffect(() => {
    const seaGoingExperience = () => {
      if (
        status &&
        statuses.includes(status) &&
        showSeaGoingExperience &&
        checkDaysDifference(dashboardBasicData?.disembarkationDateChangeTimestamp) >=
          180 &&
        !isShowWelcomePopup
      ) {
        handleModal(
          <CheckSeaServiceModal
            onCancel={() => {
              handleModal(false);
              dispatch(
                updateDashboardBasicData({
                  headers: {
                    Authorization: user.session.getIdToken().getJwtToken()
                  }
                })
              );
            }}
            updateSeaGoingExperience={updateSeaGoingExperience}
            data={dashboardBasicData?.disembarkationDateChangeTimestamp}
            firstName={dashboardBasicData?.seafarerData.firstName}
            disembarkationDate={getTime(
              new Date(userBasicInfo?.lastDisembarkationDate) / 1000
            )}
            backdrop="static"
            keyboard={false}
          />
        );
      }
    };

    // Set a timeout of 1min to delay the execution
    const timeoutId = setTimeout(seaGoingExperience, 10000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [
    dashboardBasicData,
    userBasicInfo,
    status,
    showSeaGoingExperience,
    addSeaGoingExperience,
    isShowWelcomePopup
  ]);

  if (loading) {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <Loader size="lg" />
      </div>
    );
  }

  if (allowedRoutes.length) {
    return (
      <UserStatusProvider value={{ status }}>
        {userInfoPF?.username && (
          <ProductFruits
            workspaceCode={process.env.REACT_APP_PRODUCT_FRUIT_TOKEN}
            language="en"
            user={userInfoPF}
          />
        )}

        <MapAllowedRoutes routes={allowedRoutes} basePath="/" status={status} />
      </UserStatusProvider>
    );
  }
  return null;
};

export default PrivateRoute;
