import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const CRUnauthorized = ({}) => {
  const history = useHistory();

  return (
    <div className="text-start w-100 m-auto">
      <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
        :o
      </h1>
      <div className="text-muted mb-4">
        <br />
        <strong>Unauthorized!</strong>
        <br />
        <br />
        <p className="text-start">
          You lack the authorization to access this resource. If you believe this is
          an error, please log in to the system to properly view the contact request
          in the Contact Requests section.
        </p>
        <br />
        <div className="mb-3 mb-0 text-center">
          <Button variant="danger" type="button" onClick={() => history.push('/')}>
            TO YOUR TURTLE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CRUnauthorized;
