/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { REGISTRATION_FORM_BKP, REGISTRATION_FORM_VERSION } from '../../constants';

const RegistrationComplete = () => {
  useEffect(() => {
    // Remove REG_FORM information
    try {
      localStorage.removeItem(REGISTRATION_FORM_BKP);
      localStorage.removeItem(REGISTRATION_FORM_VERSION);
    } catch (error) {}
  }, []);

  const BottomLink = () => {
    return (
      <Row className="mt-3">
        <Col>
          <div className="circle-badge mt-5 d-none d-md-block">
            <div className="center-text">
              <h4>
                {' '}
                Supersafe &amp; <br /> Free of Charge
              </h4>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <AccountLayout bottomLinks={<BottomLink />}>
      <div className="text-start w-100 m-auto">
        <div className="text-secondary mt-5 pt-5 mb-2">
          <h4 className="text-dark-50 fw-bold">WELCOME ON BOARD!</h4>
        </div>

        <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
          Thank you!
        </h1>
        <p className="text-muted mb-4">
          <br />
          <strong>
            You must now confirm your registration at TURTLE.
            <br />
            Don&apos;t worry, It&apos;s really easy!
          </strong>
          <br />
          <br />
          <p className="text-start">
            <ol>
              <li>Check your email</li>
              <li>Click the Confirmation Button in the email we have sent you</li>
              <li>Done! 🎉</li>
            </ol>
          </p>
          <br />
          If you can&apos;t find the email, please check the spam folder.
          <br />
          <br />
          Best regards,
          <br />
          <br />
          <strong>Your TURTLE Team</strong>
        </p>
      </div>
    </AccountLayout>
  );
};

export default RegistrationComplete;
