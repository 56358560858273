export const Statuses = {
  COGNITO_CREATE: 'COGNITO_CREATE',
  REJECTED: 'REJECTED',
  CONFIRMED: 'CONFIRMED',
  PROFILE_REQUESTED: 'PROFILE_REQUESTED',
  WAITING_FOR_VC: 'WAITING_FOR_VC',
  REQUEST_DATA: 'REQUEST_DATA',
  READY_FOR_MATCH: 'READY_FOR_MATCH',
  READY_FOR_MATCH_REVIEW: 'READY_FOR_MATCH_REVIEW',
  HIRED: 'HIRED',
  HIRED_REVIEW: 'HIRED_REVIEW',
  CR_ACCESS: 'CR_ACCESS'
};

export const NO_ACCESS = ['COGNITO_CREATE', 'REJECTED'];

export const RESTRICTED_ACCESS = [
  'CONFIRMED',
  'PROFILE_REQUESTED',
  'WAITING_FOR_VC',
  'REQUEST_DATA'
];

export const CR_ACCESS = [
  'PROFILE_REQUESTED',
  'READY_FOR_MATCH',
  'READY_FOR_MATCH_REVIEW',
  'WAITING_FOR_VC',
  'HIRED',
  'HIRED_REVIEW'
];

export const FULL_ACCESS = [
  'READY_FOR_MATCH',
  'READY_FOR_MATCH_REVIEW',
  'HIRED',
  'HIRED_REVIEW'
];

export const SALARY_BENCHMARK_ACCESS = [
  'PROFILE_REQUESTED',
  'WAITING_FOR_VC',
  'REQUEST_DATA',
  'READY_FOR_MATCH',
  'READY_FOR_MATCH_REVIEW',
  'HIRED',
  'HIRED_REVIEW'
];

export const SALARY_BENCHMARK_RESTRICTED_ACCESS = ['CONFIRMED', 'COGNITO_CREATE'];

export const STATUSES_GROUPS = {
  NO_ACCESS: 'NO_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  FULL_ACCESS: 'FULL_ACCESS'
};

export const getStatusGroup = (status) => {
  if (NO_ACCESS.includes(status)) {
    return STATUSES_GROUPS.NO_ACCESS;
  }
  if (RESTRICTED_ACCESS.includes(status)) {
    return STATUSES_GROUPS.RESTRICTED_ACCESS;
  }
  if (FULL_ACCESS.includes(status)) {
    return STATUSES_GROUPS.FULL_ACCESS;
  }
  return null;
};
