/* eslint-disable no-shadow */
import axios from 'axios';

const axiosConfig = {
  baseURL: process.env.REACT_APP_API
};

const axiosInstance = axios.create(axiosConfig);

const api = (axios) => {
  const get = (url, config) => axios.get(url, config);
  const deleteEndpoint = (url, headers) => axios({ method: 'DELETE', url, headers });
  const put = ({ url, headers, data, params }) =>
    axios({
      method: 'PUT',
      url,
      headers,
      params,
      data
    });
  const patch = ({ url, headers, data, params }) =>
    axios({
      method: 'PATCH',
      url,
      headers,
      params,
      data
    });
  const post = (url, headers, data) =>
    axios({
      method: 'POST',
      url,
      headers,
      data
    });

  return {
    get,
    put,
    patch,
    post,
    deleteEndpoint
  };
};

export default api(axiosInstance);
