// @flow
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

// components
import AccountLayout from '../../layouts/Authentication/AccountLayout';

// images
import logoutIcon from '../../assets/images/logout-icon.svg';

// Contexts
import { AccountContext } from '../../contexts/Accounts';

/* bottom link */
const BottomLink = () => {
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-muted">
          Back to{' '}
          <Link to="/login" className="text-info ms-1">
            <b>Log In</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Logout = () => {
  const { logout } = useContext(AccountContext);
  const dispatch = useDispatch();

  useEffect(() => {
    logout(false);
    if (window?.productFruits?.services?.destroy) {
      window.productFruits.services.destroy();
      console.log('Closing PF');
    }
  }, [dispatch]);

  return (
    <>
      <AccountLayout bottomLinks={<BottomLink />}>
        <div className="text-start w-100 mx-auto">
          <h1 className="display-1 text-primary text-start mt-0 fw-bold">
            See You Again !
          </h1>
          <p className="text-muted text-start mb-4">
            You are now successfully sign out.
          </p>

          <div className="logout-icon m-auto">
            <img src={logoutIcon} alt="" />
          </div>
        </div>
      </AccountLayout>
    </>
  );
};

export default Logout;
