export const Officers = [
  'MASTER',
  'CHIEF_OFFICER',
  '2ND_OFFICER',
  '3RD_OFFICER',
  '4TH_OFFICER',
  'CHIEF_ENGINEER',
  '2ND_ENGINEER',
  '3RD_ENGINEER',
  '4TH_ENGINEER',
  'DECK_CADET',
  'ENGINE_CADET',
  'ELECTRICIAN_CADET',
  'ELECTRO-TECHNICAL_OFFICER_(ETO)'
];

export const Ratings = [
  'ELECTRICIAN',
  'JUNIOR_ELECTRICIAN',
  'MOTORMAN',
  'FITTER',
  'OILER',
  'WIPER',
  'BOSUN',
  'AB',
  'OS',
  'CHIEF_COOK',
  'COOK',
  'STEWARD',
  'MESSMAN',
  'GAS_ENGINEER',
  'PUMP_MAN'
];
