import React from 'react';
import HyperDatepicker from '../Datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimeInput = ({
  name,
  noTime,
  value,
  disabled,
  handleDateChange,
  inline,
  customInput
}) => {
  const handleChange = (date) => {
    if (noTime) {
      const localDate = new Date(date);
      const utc0Date = Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate()
      );
      handleDateChange(utc0Date);
    } else {
      handleDateChange(date);
    }
  };

  const handleValue = () => {
    if (value) {
      if (noTime) {
        const localTimeValue = new Date(value * 1000);
        const shiftedSeconds =
          localTimeValue.getUTCHours() * 3600 +
          localTimeValue.getUTCMinutes() * 60 +
          localTimeValue.getUTCSeconds();
        if (shiftedSeconds > 0) {
          return localTimeValue;
        }
        return new Date(
          localTimeValue.getUTCFullYear(),
          localTimeValue.getUTCMonth(),
          localTimeValue.getUTCDate()
        );
      }
      return new Date(value * 1000);
    }
    return null;
  };

  return (
    <HyperDatepicker
      hideAddon
      inline={inline}
      name={name}
      showTimeSelect={!noTime}
      value={handleValue()}
      onChange={handleChange}
      dateFormat={noTime ? 'MMMM d, yyyy' : 'MMMM d, yyyy h:mm aa'}
      timeFormat="h:mm aa"
      disabled={disabled}
      customInput={customInput}
    />
  );
};
export default DateTimeInput;
