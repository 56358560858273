/* eslint-disable no-shadow */
import api from './api';

const dashboardApi = (api) => {
  const inviteFriendRequest = ({ headers, body }) => {
    const url = '/invitation/send';
    const data = body;
    return api.post(url, headers, data);
  };

  const fetchDashboardDetails = ({ headers }) => {
    const url = '/dashboard/me';
    const config = {
      headers
    };
    return api.get(url, config);
  };

  const handleSeafarerAvailability = ({ headers, body }) => {
    const url = '/dashboard/me/availability';
    const data = body;
    return api.post(url, headers, data);
  };

  return {
    inviteFriendRequest,
    fetchDashboardDetails,
    handleSeafarerAvailability
  };
};

export default dashboardApi(api);
