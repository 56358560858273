/* eslint-disable no-shadow */
import api from './api';

const dashboardApi = (api) => {
  const baseURL = '/availability';

  const updateSeafarerAvailability = ({ headers, body }) => {
    const url = `${baseURL}/available`;
    const data = body;
    return api.put({ url, headers, data });
  };

  const confirmSeafarerAvailability = ({ headers }) => {
    const url = `${baseURL}/available/confirm`;
    return api.put({ url, headers });
  };

  const updateSeafarerAvailabilityToNotAvailable = ({ headers }) => {
    const url = `${baseURL}/notAvailable`;
    return api.put({ url, headers });
  };

  const confirmSeafarerNotAvailability = ({ headers }) => {
    const url = `${baseURL}/notAvailable/confirm`;
    return api.put({ url, headers });
  };

  return {
    updateSeafarerAvailability,
    confirmSeafarerAvailability,
    updateSeafarerAvailabilityToNotAvailable,
    confirmSeafarerNotAvailability
  };
};

export default dashboardApi(api);
