import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccountLayout from '../../../layouts/Authentication/AccountLayout';
import Button from 'react-bootstrap/Button';
import publicApi from '../../../core/services/publicApi';
import Loader from '../../../components/Loader';
import CRUnauthorized from '../Components/CRUnauthorized';
import CRAlreadyReacted from '../Components/CRAlreadyReacted';
import CRGeneralError from '../Components/CRGeneralError';
import { StatusCodes, GetStatusFromErrorCode } from '../Utils/utils';
const SuccessfullyAccepted = () => {
  const history = useHistory();
  return (
    <div className="text-start w-100 m-auto">
      <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
        Awesome!,
      </h1>
      <div className="text-muted mb-4">
        <br />
        <strong>we will let the company know that you are interested.</strong>
        <br />
        <br />
        <p className="text-start">
          After reviewing your full profile the company will come back with
          information regarding next steps.
        </p>
        <br />
        <div className="mb-3 mb-0 text-center">
          <Button variant="danger" type="button" onClick={() => history.push('/')}>
            TO YOUR TURTLE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

const AcceptCRPublicPage = ({}) => {
  const [statusCode, setStatusCode] = useState(StatusCodes.DEFAULT);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search.replace('?', ''));

    let token = (urlParams.get('token') || '').trim();
    token = decodeURIComponent(token).toLowerCase() || '';
    const tokenSegments = token.split('--') || [];

    if (tokenSegments.length === 3) {
      const cr = tokenSegments[0];
      const sf = tokenSegments[1];
      const co = tokenSegments[2];

      publicApi
        .acceptCR({
          data: {
            cr: cr,
            sf: sf,
            co: co
          }
        })
        .then((res) => {
          setStatusCode(StatusCodes.OK);
        })
        .catch((err) => {
          const errorCode =
            err?.response?.data?.errorCode || StatusCodes.GENERAL_ERROR;

          setStatusCode(GetStatusFromErrorCode(errorCode));
        });
    } else {
      setStatusCode(StatusCodes.GENERAL_ERROR);
    }
  }, []);

  return (
    <AccountLayout bottomLinks={<></>}>
      {statusCode === StatusCodes.DEFAULT && <Loader size={'lg'} />}
      {statusCode === StatusCodes.OK && <SuccessfullyAccepted />}
      {statusCode === StatusCodes.GENERAL_ERROR && <CRGeneralError />}
      {statusCode === StatusCodes.UNAUTHORIZED && <CRUnauthorized />}
      {statusCode === StatusCodes.ALREADY_REACTED && <CRAlreadyReacted />}
    </AccountLayout>
  );
};

export default AcceptCRPublicPage;
