import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import 'intro.js/introjs.css';
import './App.scss';

import { Toaster } from 'react-hot-toast';
import history from './core/utils/history';
import { Account } from './contexts/Accounts';
import { ModalProvider } from './contexts/Modals';
import { HelpProvider } from './contexts/Help';
import { SeaGoingExperienceProvider } from './contexts/SeaGoingExperience';
import Modal from './components/Modal';
import PrivateRoute from './components/PrivateRoute';

import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import SignUp from './pages/SignUp';
import ConfirmUser from './pages/ConfirmUser';
import RegistrationComplete from './pages/RegistrationComplete';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import AcceptCRPublicPage from './pages/PublicContactRequest/Accept';
import RejectCRPublicPage from './pages/PublicContactRequest/Reject';
import { Provider } from 'react-redux';
import { PrismicProvider } from '@prismicio/react';
import { client } from './core/services/prismic';
import { configureStore } from './redux/store';

const App = () => (
  <Provider store={configureStore({})}>
    <PrismicProvider client={client}>
      <div className="App">
        <Toaster
          position="top-right"
          containerStyle={{ position: 'sticky' }}
          toastOptions={{
            style: {
              marginRight: '20px',
              fontFamily: 'RedHatDisplay',
              minHeight: '60px'
            }
          }}
        />
        <HelpProvider>
          <Account>
            <Router history={history}>
              <SeaGoingExperienceProvider>
                <ModalProvider>
                  <Modal />
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route
                      exact
                      path="/reset-password-request"
                      component={ResetPasswordRequest}
                    />
                    <Route exact path="/new-password" component={NewPassword} />
                    <Route exact path="/sign-up" component={SignUp} />
                    <Route exact path="/confirmUser" component={ConfirmUser} />
                    <Route
                      exact
                      path="/registration-complete"
                      component={RegistrationComplete}
                    />
                    <Route
                      exact
                      path="/public/contact-request/accept"
                      component={AcceptCRPublicPage}
                    />
                    <Route
                      exact
                      path="/public/contact-request/reject"
                      component={RejectCRPublicPage}
                    />
                    <PrivateRoute />
                  </Switch>
                </ModalProvider>
              </SeaGoingExperienceProvider>
            </Router>
          </Account>
        </HelpProvider>
      </div>
    </PrismicProvider>
  </Provider>
);

export default App;
