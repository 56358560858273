import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const CRGeneralError = ({}) => {
  const history = useHistory();

  return (
    <div className="text-start w-100 m-auto">
      <h1 className="display-1 text-primary text-start mt-md-0 pt-md-0 fw-bold">
        :(
      </h1>
      <div className="text-muted mb-4">
        <br />
        <strong>really sorry!</strong>
        <br />
        <br />
        <p className="text-start">
          We encountered errors while processing your request. Please log in to the
          system for more details.
        </p>
        <br />
        <div className="mb-3 mb-0 text-center">
          <Button variant="danger" type="button" onClick={() => history.push('/')}>
            TO YOUR TURTLE ACCOUNT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CRGeneralError;
