import React, { createContext, useState } from 'react';

const SeaGoingExperienceContext = createContext();

const SeaGoingExperienceProvider = ({ children }) => {
  const [showSeaGoingExperience, setShowSeaGoingExperience] = useState(true);
  const [addSeaGoingExperience, setAddSeaGoingExperience] = useState(false);

  const handleShowSeaGoingExperience = () => {
    setShowSeaGoingExperience((prevSeaGoingExperience) =>
      prevSeaGoingExperience ? false : true
    );
  };

  const handleAddSeaGoingExperience = () => {
    setAddSeaGoingExperience((prevAddSeaGoingExperience) =>
      prevAddSeaGoingExperience ? false : true
    );
  };

  const updateSeaGoingExperience = () => {
    handleShowSeaGoingExperience();
    handleAddSeaGoingExperience();
  };

  return (
    <SeaGoingExperienceContext.Provider
      value={{
        showSeaGoingExperience,
        handleShowSeaGoingExperience,
        addSeaGoingExperience,
        handleAddSeaGoingExperience,
        updateSeaGoingExperience
      }}
    >
      {children}
    </SeaGoingExperienceContext.Provider>
  );
};

export { SeaGoingExperienceContext, SeaGoingExperienceProvider };
