import React, { useState, useContext } from 'react';
import GenericModal from '../../../components/GenericModal';
import { AccountContext } from '../../../contexts/Accounts';
import toast from 'react-hot-toast';
import Texts from '../../../data/texts';
import { Col, Row } from 'react-bootstrap';
import hubspotApi from '../../../core/services/hubspotApi';
import { useHistory } from 'react-router-dom';
import handleDate from '../../../core/utils/get-ddMMyyy-date';

const CheckSeaServiceModal = ({
  onCancel,
  firstName,
  disembarkationDate,
  updateSeaGoingExperience,
  reduced = false
}) => {
  const { user } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleCancel = () => {
    setLoading(true);
    toast.promise(
      hubspotApi
        .updateLastSeaServiceDate({
          headers: {
            Authorization: user.session.getIdToken().getJwtToken()
          }
        })
        .finally(() => {
          setLoading(false);
          if (onCancel) onCancel();
        }),
      {
        loading: 'Updating your last sea service date...',
        success: Texts.DASHBOARD.SEA_SERVICE.ON_SUCESS,
        error: Texts.DASHBOARD.SEA_SERVICE.ON_ERROR
      }
    );
  };

  const handleConfirm = () => {
    onCancel();
    updateSeaGoingExperience();
    history.push({ pathname: '/profile' });
  };

  return (
    <GenericModal
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onHide={handleCancel}
      className="info"
      confirmButtonVariant="info"
      title="When was your last  sea service ?"
      cancelButtonText="Yes"
      confirmButtonText="No, I did another assignment meanwhile"
      closeButton={true}
      loading={loading}
      skipCloseOnConfirm={true}
    >
      {!reduced && (
        <>
          <Row className="mt-2 ms-2">
            <Col>
              <h4>Hi {firstName}!</h4>
            </Col>
          </Row>
          <Row className="mt-2 ms-2 d-flex align-items-center">
            <p>
              Did your last assignment end on{' '}
              <span className="h5 heading-box-height head-lg text-primary">
                {handleDate(disembarkationDate, 'dd.MM.yyyy')}?
              </span>
            </p>
          </Row>
        </>
      )}
    </GenericModal>
  );
};

export default CheckSeaServiceModal;
