// @flow
import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import User from './user/reducers';
import FeatureFlags from './featureFlags/reducers';
import Dashboard from './dashboard/reducers';

export default combineReducers({
  Layout,
  User,
  FeatureFlags,
  Dashboard
});
